import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw, { styled } from "twin.macro";

const LiveCoursesContainer = tw.div`my-8 flex flex-wrap gap-8 justify-center`;
const LiveCourseCard = styled.div`
  ${tw`bg-white rounded-lg shadow-lg p-6 flex flex-col items-center`}
  ${tw`transition duration-300 transform hover:-translate-y-1 hover:shadow-2xl`}
`;
const CourseImage = tw.img`w-64 h-64 rounded object-cover mb-4`;
const CourseTitle = styled.h1`
  ${tw`text-3xl font-bold text-gray-800 relative inline-block whitespace-nowrap`}
`;
const CoursePrice = tw.p`text-blue-500 font-semibold text-lg mt-2`;
const CourseDetails = tw.div`flex items-center mt-4`;
const DetailItem = tw.div`flex items-center text-gray-600 mr-4`;
const DetailIcon = tw.span`mr-1 text-lg`;
const DetailText = tw.p`text-sm`;
const CourseDescriptionWrapper = tw.div`mt-4 text-gray-800 overflow-auto h-32 w-64`;

const CourseDescription = tw.p`mt-4 text-gray-800`;
const CTAButton = tw.button`mt-4 px-6 py-3 bg-blue-500 text-white font-semibold text-lg rounded-md shadow-md hover:bg-blue-600 transition duration-300`;

const ClassLimit = styled.span`
  ${tw`text-red-600 font-semibold`}
`;

export default () => {
  const liveCourses = [
    {
      id: 1,
      title: "បង្កើត website ពី 0 ដល់ 100",
      price: "$49.99",
      date: "August 01, 2023",
      time: "09:00 PM - 11:00 PM",
      description: "វគ្គផ្ទាល់នេះត្រូវបានរចនាឡើងសម្រាប់បុគ្គលដែលចង់រៀនពីរបៀបបង្កើតគេហទំព័រ (web development) ពីដំបូង ចាប់ផ្តើមពី 0 និងឡើងដល់ 100។ មិនថាអ្នកជាអ្នកចាប់ផ្តើមដំបូង ឬមានចំណេះដឹងសរសេរកូដខ្លះទេ វគ្គសិក្សានេះនឹងណែនាំអ្នកតាមរយៈមូលដ្ឋានគ្រឹះនៃ web development។ អ្នកនឹងរៀន HTML, CSS, និង Javascript ក៏ដូចជាក្របខ័ណ្ឌពេញនិយមដូចជា ReactJS ។ យើងក៏នឹងរុករក Tailwind CSS សម្រាប់ការកំណត់រចនាប័ទ្ម និងធ្វើការជាមួយទិន្នន័យ JSON ផងដែរ។ ឆមាសទី 2 នឹងរៀនអំពី backend ជាមួយ ExpressJS, NodeJS និង database ជាមួយ MySQL និង Firestore។ ហើយយើងនឹងរៀន deploy តាម AWS (Amazon Web Services) ជា cloud technology ដែលវាយដាច់គេក្នុងពិភពលោក! ចុះឈ្មោះឥឡូវនេះ ដើម្បីទទួលបានកន្លែងរបស់អ្នក!",
      technologies: ["HTML", "CSS", "Javascript", "ReactJS", "Tailwind", "JSON Data"],
      image: "https://firebasestorage.googleapis.com/v0/b/uddompang-dfd58.appspot.com/o/liveCoureses%2FWeb%20dev.png?alt=media&token=04a78653-54a2-427e-8706-db01a185bab4",
      classLimit: 50,
      isAlmostFull: true,
    },
    {
      id: 2,
      title: "ថ្នាក់កូដដំបូង",
      price: "$119.99",
      date: "August 01, 2023",
      time: "09:00 PM - 11:00 PM",
      description: "ចូលរួមវគ្គសិក្សាណែនាំការសរសេរកូដរបស់យើងសម្រាប់អ្នកចាប់ផ្តើមដំបូងដែលចង់ចាប់ផ្តើមដំណើរសរសេរកូដ។ នៅក្នុងវគ្គផ្ទាល់នេះ អ្នកនឹងរៀនពីមូលដ្ឋានគ្រឹះនៃការសរសេរកម្មវិធី រួមទាំងគោលគំនិតសំខាន់ៗ និងបច្ចេកទេសដោះស្រាយបញ្ហា។ យើងនឹងគ្របដណ្តប់លើភាសាសរសេរកម្មវិធីជាមូលដ្ឋានដូចជា Python, HTML និង CSS ។ លើសពីនេះទៀត អ្នកនឹងស្វែងយល់ពី Pseudo Code និង flowchart។ វគ្គសិក្សានេះត្រូវបានរចនាឡើងដើម្បីផ្តល់ឱ្យអ្នកនូវមូលដ្ឋានគ្រឹះដ៏រឹងមាំមួយក្នុងការសរសេរកម្មវិធី និងជម្រុញអ្នកឱ្យបន្តការរុករកការសរសេរកូដរបស់អ្នក។ កៅអីមានកំណត់ ដូច្នេះសូមចុះឈ្មោះឥឡូវនេះ ដើម្បីទទួលបានកន្លែងរបស់អ្នក!",
      technologies: ["Basic", "Charts", "Python", "HTML", "CSS"],
      image: "https://firebasestorage.googleapis.com/v0/b/uddompang-dfd58.appspot.com/o/liveCoureses%2FIntroToProgramming.png?alt=media&token=1ad73429-852a-4142-9ba9-1455f6f19496",
      classLimit: 50,
      isAlmostFull: true,
    },
  ];

  return (
    <AnimationRevealPage>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <div>
            <CourseTitle tw="text-4xl font-bold text-gray-800 mb-4 relative inline-block">
              Live Courses
              <span tw="absolute h-1 w-full left-0 bottom-0 bg-blue-500"></span>
            </CourseTitle>
            <LiveCoursesContainer>
              {liveCourses.map((course) => (
                <LiveCourseCard key={course.id}>
                  <CourseImage src={course.image} alt={course.title} />
                  <CourseTitle>{course.title}</CourseTitle>
                  <CoursePrice>
                    {course.price}
                    {course.isAlmostFull && (
                      <ClassLimit> (Full)</ClassLimit>
                    )}
                  </CoursePrice>
                  <CourseDetails>
                    <DetailItem>
                      <DetailIcon>🗓️</DetailIcon>
                      <DetailText>{course.date}</DetailText>
                    </DetailItem>
                    <DetailItem>
                      <DetailIcon>⏰</DetailIcon>
                      <DetailText>{course.time}</DetailText>
                    </DetailItem>
                  </CourseDetails>
                <CourseDescriptionWrapper>
                    <CourseDescription>{course.description}</CourseDescription>
                </CourseDescriptionWrapper>                  
                <CTAButton>Enroll Now</CTAButton>
                </LiveCourseCard>
              ))}
            </LiveCoursesContainer>
          </div>
          <Footer />
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'user',
  initialState: {
    active: false,
    email: '',
    token: '',
    darkMode: false,
    foreverPackage: false,
  },
  reducers: {
    login(state, action) {
      state.active = true;
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.foreverPackage = action.payload.foreverPackage;
    },
    logout(state) {
      state.active = false;
      state.email = '';
      state.token = '';
      state.foreverPackage = false;
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    }
  },
});

export const authAction = authSlice.actions;

export default authSlice;

import React, { useState, useEffect, useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";
import tw from "twin.macro";
import styled, { keyframes } from 'styled-components';
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import Header from "Header";

import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import LoadingScreen from "components/LoadingScreen";

import News from "components/News";
import { DarkModeContext } from "components/DarkModeContext";




const TitleContainer = tw.button` transition duration-300 ease-in-out hover:shadow-lg flex justify-around items-center w-full h-16 mb-10 mt-5 border border-black p-5 sm:p-5`
const TitleDiv = tw.div`flex items-center flex-col p-5 sm:p-5`
const Title = tw.h1`text-lg sm:text-lg lg:text-2xl md:text-2xl mr-2 text-secondary-700 whitespace-nowrap text-center`

const ContentWithPadding = tw.div`pb-10 lg:pb-12`;


const SectionContainer = tw(ContentWithPadding)``;





const repulseAnimation = keyframes`
  0%, 100% {
    transform: translate(0, 0);
    color: #167BE7;
  }
  50% {
    color: white; 
  }
`;
const Greetings = styled(HeadingBase)`
  ${tw`text-gray-700 text-lg`}
  animation: ${repulseAnimation} 3s ease-in-out infinite;
`;

const SectionHeading = styled.h1(({darkMode}) => [
  tw`text-lg sm:text-lg lg:text-2xl md:text-2xl mr-2 whitespace-nowrap text-center`,
  darkMode ? tw`text-white` : tw`text-black`
]);

const Container = styled.div(({darkMode}) => [
  tw`-mx-8 -mt-8 pt-8 px-8`,
  darkMode ? tw`bg-gray-900` : tw`bg-gray-100`
]);

export default ({

}) => {

  const [data, setData] = useState([])
  const [title, setTitle] = useState([])
  const [copywrite, setCopywrite] = useState([])
  const [logos, setLogos] = useState([])
  const [promotionPic, setPromotionPic] = useState([])
  const [userAmount, setUserAmount] = useState([])

  const [learning, setLearning] = useState([]);

  const getData = async () => {
    let response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/resources/landingpage')
    response = await response.json()

    setData(response.dataList)

    setTitle(response.updatesList[0].title)
    setCopywrite(response.updatesList[0].copywrite)
    setPromotionPic(response.updatesList[0].sale)
    setUserAmount(response.updatesList[0].userAmount)

    setLogos(response.logoList)
  }


  const email = useSelector((state) => state.auth.email)


  const navigate = useNavigate()
  const navHandler = () => {
    navigate('/content')
  }
  const passData = p => {
    if(p.courseStatus == true) {
      navigate('/content', {state: p})
    } else {
      alert('Course will be added soon!')
    }
  }

  const showCopyWrite = () => {
    setShowModal(!showModal);
  }
  
  const showPopup = () => {
    setPopup(!popup);
  }

  
  const [isLoading, setIsLoading] = useState(true)

  const [popup, setPopup] = useState(true)

  useEffect(()=> {
    getData().then(() => {
      setIsLoading(false) 
    })
  }, [])

  const noOfCourses = data.length;


  
  const [showModal, setShowModal] = useState(false);

  if(isLoading) {
    return <LoadingScreen/>
  }

  const { darkMode } = useContext(DarkModeContext)


  return (
    <AnimationRevealPage enabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8 bg-gray-100"
                style={{
                  backgroundColor: darkMode ? "#2c3e50" : "#f0f0f0",
                  color: darkMode ? "#f0f0f0" : "#2c3e50",
                }}
      >
        <Content2Xl>
          <Header/>

          <SectionHeading className="nokora"
            style={{
              color: darkMode ? "#E5E4E2" : "black",
            }}
          >ថ្នាក់កំពុងរៀន</SectionHeading>
          <Greetings className="monoRoman">{email}</Greetings>
          <TitleContainer style={{borderColor: darkMode && 'white'}}>
            <TitleDiv >
              <Title style={{color: darkMode && '#E5E4E2'}} className="nokora">{title}</Title>
            </TitleDiv>
          </TitleContainer>
          <SectionContainer id="landingPageDemos">
            <News/>
          </SectionContainer>
        </Content2Xl>
      </Container>
      <MiniCenteredFooter/>
    </AnimationRevealPage>
  );
};



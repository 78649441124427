import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import { useSelector } from "react-redux";
import CurrentlyLearningSection from "./CurrentlyLearning";

export default () => {
  const [learning, setLearning] = useState([]);
  const email = useSelector((state) => state.auth.email);
  const status = useSelector((state) => state.auth.active);
  const token = useSelector((state) => state.auth.token);
  const [refresh, updateRefresh] = useState(0);
  const [courseHistory, setCourseHistory] = useState();

  const fetchLearning = async () => {
    if (status) {
      try {
        const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/learning', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: email }),
        });
        const data = await response.json();
        const courseID = Object.keys(data);


        //access the object data

        const fetchedCourses = await Promise.all(
          courseID.map(async (course) => {
            const response = await fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/courses/preview/${course}`);
            const jsonResponse = await response.json();
            return jsonResponse;
          })
        );
        setCourseHistory(data);
        setLearning(fetchedCourses.filter(course => !course.error));
      } catch (error) {
        console.error("Failed to fetch learning data:", error);
      }
    }
  }

  useEffect(() => {
    fetchLearning();
  }, [status, token, email, refresh]);

  const deleteHistory = (user, id) => {
    fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: user, id: id }),
    })
      .then((response) => {
        updateRefresh(refresh + 1);
      })
      .catch((error) => console.error(error));
  };



  return (
    <>
      <CurrentlyLearningSection
        items={learning}
        deleteHistory={deleteHistory}
        courseHistory={courseHistory}
        css={tw`mt-1`}
      />
    </>
  );
};

import React, { useState } from 'react';
import tw from 'twin.macro';
import '../../styles/App.css';
const modalContainer = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, .2)',

  }

const modal = {
    backgroundColor: 'white',
    width: '80%',
    maxWidth: 600,
    borderRadius: 10,
    boxShadow: '10 5 5 red',
    zIndex: 10000,
}

const modalContent = {
    padding: 20,
}

const CloseButton = tw.button`text-black bg-white border-2 border-black hover:bg-blue-200 text-white font-bold py-2 px-4 rounded-md w-full`
const SignUpButton = tw.button`mb-3 text-black bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md w-full`

const Text = tw.h1`text-center font-bold mb-5 text-2xl`
const SpanText = tw.span`text-black`

function HomePageModal({showModal, toggleModal, copywrite}) {
  function redirectToTelegram() {
    window.open("https://t.me/uddomsite", "_blank");
  }

  return (
    <>
      {showModal ? (
        <div style={modalContainer} onClick={toggleModal}>
          <div style={modal}>
            <div style={modalContent}>
              <Text className="nokora">{copywrite}</Text>
              <SignUpButton className="monoRoman" onClick={redirectToTelegram}>Signup</SignUpButton>

              <CloseButton className="monoRoman" onClick={toggleModal}><SpanText>Close</SpanText></CloseButton>

            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default HomePageModal;
import React, {useContext, useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LoadingScreen from "components/LoadingScreen";
import { DarkModeContext } from "components/DarkModeContext";

const PreviewCards = tw.div`flex flex-wrap -mr-12`;
const PreviewCardContainer = tw.div`mt-12 mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 pr-12 hover:text-gray-700`;
const PreviewCard = styled.a`
  ${tw`bg-white block border-2 rounded-lg border-gray-400 shadow-raised duration-300 hover:-translate-y-3`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  box-shadow: -0.5rem -0.5rem black;
  
  &:hover {
    box-shadow: none;  // remove the shadow on hover
  }

`;
const PreviewCardImageContainer = tw.div` `;
const CardImage = tw.img`flex items-center justify-center `
const TextHolder = tw.div`flex text-center flex-col m-3 lg:h-48 md:h-48 p-2`;
const TextHeader = tw.h1`text-lg md:text-sm font-bold lg:text-lg xl:text-lg sm:text-xs`;
const TextContent = tw.p`mt-1 lg:text-lg text-lg`;
const CareerTitle = tw.h1`font-bold text-center h-16 bg-[#167BE7] text-white rounded-t-lg items-center justify-center flex`;
const LevelHolder = tw.div`flex justify-center items-center h-16 border-t-2 border-dashed text-white rounded-b-lg`;
const Level = tw.h1`text-center text-[#167BE7] text-lg`;
const LevelLabel = tw.span`text-center text-black text-lg`;
const FilterHolder = styled.div`
  ${tw`flex justify-center lg:justify-start xl:justify-start md:justify-start items-center w-full flex-wrap`}
  @media (max-width: 640px) {  // Small screens (e.g. sm: breakpoint in Tailwind)
    display: ${props => (props.show ? 'flex' : 'none')};
  }
`;

const ToggleFiltersButton = styled.button`
  ${tw`sm:hidden mx-auto flex justify-center items-center mb-4 px-4 py-2 text-white rounded border-black w-full border-2 text-black cursor-pointer transition-colors duration-300 ease-in-out focus:outline-none`}
  &:hover {
    ${tw`bg-opacity-75 bg-gray-200 text-black`}
  }
`;

export default ({
  passData, 
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState("");
  useEffect(()=> {
    fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/roadmap').then(res => {return res.json()}).then((data) => {
        setData(data)
    }).then(() => setLoading(false))
  }, [])

  let filteredData = data;
  if (filter) {
    filteredData = data.filter(item => item.level === filter);
  }

  if(loading) {
    return <LoadingScreen/>
  }

  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "100%"
    },
  };

  const FilterButton = styled.button`
  ${tw`mr-2 px-4 mb-2 py-2 text-white rounded-full ml-4 border-2 text-black w-32 cursor-pointer transition-colors duration-300 ease-in-out focus:outline-none`}
  background-color: ${props => props.isActive ? '#167BE7' : 'transparent'};
  color: ${props => props.isActive ? 'white' : 'black'};
  background-color: ${props => props.isActive && darkMode && '#010048'};

  &:hover {
    ${tw`bg-opacity-75 bg-gray-200 text-black`}
  }
`;


  const { darkMode } = useContext(DarkModeContext)



  return (
    <>
      <ToggleFiltersButton style={{color: darkMode && 'white', borderColor: darkMode && 'white'}} onClick={() => setShowFilters(!showFilters)}>
        {showFilters ? "Hide Filters" : "Show Filters"}
      </ToggleFiltersButton>

      <FilterHolder show={showFilters} tw="mb-4">
        <FilterButton style={{color: darkMode && 'white'}} isActive={!filter} onClick={() => setFilter("")}>All</FilterButton>
        <FilterButton style={{color: darkMode && 'white'}} isActive={filter === "Beginner"} onClick={() => setFilter("Beginner")}>Beginner</FilterButton>
        <FilterButton style={{color: darkMode && 'white'}} isActive={filter === "Intermediate"} onClick={() => setFilter("Intermediate")}>Intermediate</FilterButton>
        <FilterButton style={{color: darkMode && 'white'}} isActive={filter === "Advanced"} onClick={() => setFilter("Advanced")}>Advanced</FilterButton>
      </FilterHolder>
      <PreviewCards >
              {filteredData.map((item) => (
                <PreviewCardContainer key={item} >
                  <PreviewCard 
                    style={{boxShadow: darkMode && '-0.5rem -0.5rem #E5E4E2', border: darkMode && '1px solid black', backgroundColor: darkMode && '#E5E4E2'}}
                    initial="rest" whileHover="hover" onClick={event => passData(item)} target="_blank">
                    <CareerTitle style={{backgroundColor: darkMode && '#010048', color: darkMode && '#E5E4E2'}}>
                      <TextHeader className="monoRoman">{item.title}</TextHeader>
                    </CareerTitle>
                    <PreviewCardImageContainer>
                      <CardImage draggable= 'false' src={item.image}></CardImage>
                    </PreviewCardImageContainer>
                    <TextHolder style={{overflow: "auto"}}>
                      <TextContent className="nokora" style={{color: darkMode && 'black'}}>{item.description}</TextContent>

                    </TextHolder>
                    <LevelHolder>
                      <Level className="mono"><LevelLabel>Level:</LevelLabel> {item.level}</Level>
                    </LevelHolder>
                  </PreviewCard>
                </PreviewCardContainer>
              ))}
      </PreviewCards>
      </>
  );
};



import React, { useContext } from 'react';
import tw from 'twin.macro';
import { DarkModeContext } from './DarkModeContext';
import '../styles/Testimonial.css'

const SearchContainer = tw.div` m-8`;
const SearchInput = tw.input`w-full p-2 rounded border border-gray-500 text-gray-700 focus:border-blue-500 focus:outline-none`;
const SearchBar = ({ handleSearchChange }) => {
  const { darkMode } = useContext(DarkModeContext)

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder="Search for courses..."
        onChange={handleSearchChange}
        className={darkMode ? 'dark-placeholder' : ''}
        style={{ backgroundColor: darkMode && '#E5E4E2', color: darkMode && 'black' }}
      />
    </SearchContainer>
  );
};

export default SearchBar;

import React from 'react';
import tw from 'twin.macro';
import { SectionHeading as HeadingBase } from 'components/misc/Headings';
import { SectionDescription as DescriptionBase } from 'components/misc/Typography';
import styled from "styled-components";

const SectionContainer = tw.div`bg-gray-200 py-8 px-5`;
const SectionHeading = tw(HeadingBase)`text-blue-800 mb-4`;
const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-2xl text-xl mb-6`;

const FeedbackContainer = tw.div`flex flex-wrap justify-center items-start`;
const FeedbackCard = tw.div`bg-white shadow-md p-4 rounded-lg m-2 w-full md:w-1/4`;
const FeedbackContent = tw.p`text-gray-700 text-center`;

const IframeContainer = tw.div`my-8 grid grid-cols-1 md:grid-cols-3 gap-4`;
const StudentWorkIframe = styled.iframe`
  ${tw`w-full lg:h-112 h-80 md:h-96 border border-black rounded-lg`};
  box-shadow: -0.5rem -0.5rem 0 0 #2c3e50;
`;



const feedbackData = [
  {
    content: '"វីដេអូទាំងអស់ពន្យល់បានលំអិតល្អណាស់បង ហេីយមេរៀនខ្ញុំរៀនបានយល់ច្រេីន។ រៀនមិនស្តាយលុយទេ ❤️"',
  },
  {
    content: '"សម្រាប់ការបង្រៀនរបស់បងងាយឆាប់ចាំបានហេីយបងបង្រៀនមិនសូវលឿនពេក"',
  },
  {
    content: '"It is a really good platform for beginner and intermediate levels"',
  },
  {
    content: '"មេរៀនញុំរៀនទាន់ទាំងអស់បង"',
  },
  {
    content: '"អាត្មាអាចចាប់យល់បានខ្លះៗលោកគ្រូ ហេីយការបង្រៀនល្អអាត្មាយល់បាន"',
  },
  {
    content: 'I highly recommend this platform to everyone. The quality of the content is top-notch, and the instructors are very knowledgeable.',
  },
];

const CustomerFeedbackSection = () => {
  return (
    <SectionContainer>
      <SectionHeading className="mono">Projects & Feedbacks</SectionHeading>
      <SectionDescription className="nokora">
        នេះជា project របស់សិស្ស និង feedback ការគាំទ្ររបស់គាត់
      </SectionDescription>
      <FeedbackContainer>
        {feedbackData.map((feedback, index) => (
          <FeedbackCard key={index}>
            <FeedbackContent className="nokora">{feedback.content}</FeedbackContent>
          </FeedbackCard>
        ))}
      </FeedbackContainer>
      <IframeContainer>
        <StudentWorkIframe src="https://stoic-123.github.io/AboutKimlong/" title="Student Work 1"/>
        <StudentWorkIframe src="https://aboutkimlong.site/" title="Student Work 2" />
        <StudentWorkIframe src="https://stoic-123.github.io/Online-Shop/" title="Student Work 3" />
      </IframeContainer>


    </SectionContainer>
  );
};

export default CustomerFeedbackSection;

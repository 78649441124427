import React, { useContext, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import { useState } from "react";
import Courses from "components/learning/MyCourses.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MyRoadmap from "components/learning/MyRoadmap";
import { DarkModeContext } from "components/DarkModeContext";

export default function AllCourses() {
  
  const status = useSelector((state) => state.auth.active)
  const email = useSelector((state) => state.auth.email)

  const navigate = useNavigate();
  const passData = (p, email) => {
    if (status){
      if(true) {
        navigate('/roadmapList', {state: p})
      } 
      else {
        alert('Roadmap will be added soon!')
      }
    } 
    else {
      navigate('/signin')
    }
  }

  const { darkMode } = useContext(DarkModeContext)


  return (
    <AnimationRevealPage>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8"
        style={{
          backgroundColor: darkMode ? "#2c3e50" : "white",
          color: darkMode ? "#f0f0f0" : "#2c3e50",
        }}
      >
        <Content2Xl>
          <Header />
            <MyRoadmap passData={passData}/>
          <Footer/>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

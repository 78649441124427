import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as TikTokIcon } from "../../images/tiktok-icon.svg";
import { useSelector } from "react-redux";
import { DarkModeContext } from "components/DarkModeContext";


const Container = tw(ContainerBase)` text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-center`;
const LogoImg = tw.img`w-3/12 sm:w-20 md:w-1/12`;
const LogoText = tw.h5`ml-2 text-2xl content-center font-black text-gray-900 tracking-wider`;

const SocialLinksContainer = tw.div`mt-10 flex flex-row justify-center items-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-900 hover:text-gray-700 transition duration-300 mx-4`}
  svg {
    ${tw`w-6 h-6`}
  }
`;


const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-700`
export default () => {

  const { darkMode } = useContext(DarkModeContext)

  return (
    <Container style={{backgroundColor: darkMode && '#2c3e50'}}>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>

          <SocialLinksContainer>
            <SocialLink target= "_blank" href="https://facebook.com/uddom1234">
              <FacebookIcon style={{color: darkMode && 'white'}}/>
            </SocialLink>
            <SocialLink target= "_blank" href="https://www.tiktok.com/@uddomp">
              <TikTokIcon style={{color: darkMode && 'white'}}/>
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText className="monoRoman" style={{color: darkMode && 'white'}}>
            &copy; Copyright 2022, Uddom Pang. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};


import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

const ModalBackdrop = styled.div`
  ${tw`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50`}
`;

const ModalContent = styled.div`
  ${tw`bg-white p-6 rounded-lg shadow-lg max-w-sm`}
`;

const ModalHeader = tw.h2`text-xl font-semibold text-center`;
const ModalBody = tw.p`text-lg text-gray-600 mt-4 text-center`;
const CloseButton = styled.button`
  ${tw`bg-blue-500 text-white rounded px-4 py-2 mt-4 hover:bg-blue-700 focus:outline-none items-center w-full`}
`;

const ExclusiveModal = ({ onClose }) => (
  <ModalBackdrop onClick={onClose}>
    <ModalContent onClick={e => e.stopPropagation()}>
      <ModalHeader className='mono'>Exclusive Access</ModalHeader>
      <ModalBody className='monoRoman'>
        This section is reserved for exclusive lifetime students only.
      </ModalBody>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </ModalContent>
  </ModalBackdrop>
);

export default ExclusiveModal;

import React, { useState, useRef, useEffect, useContext } from 'react';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from './functions/progressBar';
import { useSelector } from 'react-redux';
import { DarkModeContext } from './DarkModeContext';

const DropdownWrapper = tw.div`relative inline-block text-left`;
const DropdownButton = tw.button`mt-4 lg:mt-0 text-lg transition duration-300 font-medium pb-1 border-b-2 text-gray-700 mr-12 border-gray-400 hocus:border-gray-700`;
const DropdownContent = tw.div`overflow-scroll h-64 absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`;
const DropDownItemContainer = tw.button`px-2 py-2`
const DropDownContainer = tw.div`flex items-center space-x-2 hover:bg-gray-100 hover:text-gray-900 rounded flex flex-row w-48 mb-2`
const DropDownImage = tw.img `w-16 h-16 object-cover rounded `
const DropDownText = tw.p`text-sm font-medium text-gray-700 text-left`
const SpanWrap = tw.button`ml-2 text-green-500`
const Container = tw.div`flex p-2 justify-between items-center`
const CompletedDiv = tw.div`flex flex-col justify-start items-start w-full`;
const CompletedText = tw.p`text-sm text-green-900 font-bold`;
const DropdownMenu = ({ title, items, deleteHistory, courseHistory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const navigate = useNavigate()

  const passData = (p) => {
    navigate('/content', {state: p})
  }

  const handleDeleteHistory = async (user, id) => {
    // Call the deleteHistory prop function and pass the parameters
    await deleteHistory(user, id);
  };

  const { darkMode } = useContext(DarkModeContext)
  const email = useSelector((state) => state.auth.email); // Get the email state



  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownButton className="nokora" onClick={toggleDropdown} style={{ color: darkMode && "#E5E4E2" }}>
        {title}
        <span tw="ml-2">
          {isOpen ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
        </span>
      </DropdownButton>
      {isOpen && (
        items.length > 0 ? (
        <DropdownContent tw="py-1 h-2">
          {items.map((item) => (
            <Container style={{backgroundColor: darkMode && '#2c3e50', border: '1px solid'}}>
            <DropDownItemContainer key={item.id} onClick={() => passData(item)}>
              <DropDownContainer>
                <DropDownImage src={item.image} alt={item.header}/>
                <div>
                <DropDownText className="monoRoman" style={{color: darkMode && 'white'}}>{item.header}</DropDownText>
                {courseHistory[item.id].completed && (
                  <CompletedText>Completed</CompletedText>
                )}
                </div>
                

                </DropDownContainer>
                {!courseHistory[item.id].completed && (
                  <ProgressBar current={courseHistory[item.id].index} total={courseHistory[item.id].length}/> 
                )}

            </DropDownItemContainer>

            <SpanWrap onClick={() => handleDeleteHistory(email, item.id)}>
              <FontAwesomeIcon icon={faCheck} />
            </SpanWrap>
            </Container>
          ))}
        </DropdownContent>
        ): (
          <>
          </>
        )
      )}
    </DropdownWrapper>
  );
};

export default DropdownMenu;

import React, { useContext, useEffect } from 'react';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ProgressBar from './functions/progressBar';
import { useSelector } from 'react-redux';
import { DarkModeContext } from './DarkModeContext';

const Container = tw.div`flex flex-row items-center`;
const EmptyContainer = tw.div`flex flex-col justify-center items-center`;
const CheckButton = tw.div`z-[2] text-green-700 text-2xl flex w-full justify-end p-2 border border-b-0 border-black border-2 rounded-lg rounded-b-none bg-white`;

const Title = tw.h2`text-2xl font-bold text-gray-700`;
const PreviewCards = tw.div`flex flex-wrap justify-center`;
const PreviewCardContainer = tw.div`mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 px-4 mb-24 hover:-translate-y-3 duration-300 hover:text-gray-700 flex-grow flex-shrink-0`;
const PreviewCard = tw.a`bg-white block rounded-b-lg border-black border-2 shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const CardImage = tw.img`flex items-center justify-center`;
const TextHolder = tw.div`flex text-center flex-col m-3 lg:h-48 md:h-48`;
const TextHeader = tw.h1`text-lg font-bold text-black`;
const TextContent = tw.p`mt-1 lg:text-lg text-lg overflow-auto text-black`;
const PreviewButton = tw.button`w-full rounded-b-lg rounded-t-none py-5 border border-t-black border-t-2 font-semibold text-white`;
const NoItemsMessage = tw.p`text-lg text-gray-600`;
const LearnButton = tw.button`mt-4 px-6 py-3 bg-blue-500 text-white font-semibold text-lg rounded-md shadow-md hover:bg-blue-600 transition duration-300`;
const CompletedDiv = tw.div`flex flex-col justify-start items-start w-full`;
const CompletedText = tw.p`text-lg text-green-900 font-bold`;


const CurrentlyLearningSection = ({ title, items, deleteHistory, courseHistory }) => {
  const navigate = useNavigate();

  const passData = (item) => {
    navigate('/content', { state: item });
  };

  const handleDeleteHistory = async (user, id) => {
    // Call the deleteHistory prop function and pass the parameters
    await deleteHistory(user, id);
  };

  const { darkMode } = useContext(DarkModeContext)


  if (!items || items.length === 0) {
    return (
      <EmptyContainer>
        <NoItemsMessage>អត់ទាន់បានរៀនអីទេ.</NoItemsMessage>
        <LearnButton onClick={() => navigate('/all')}>រៀនឥឡូវ</LearnButton>
      </EmptyContainer>
    );
  }

  const sortedItems = [...items].sort((a, b) => {
    return new Date(courseHistory[b.id]?.time) - new Date(courseHistory[a.id]?.time);
  });

  
  return (
    <Container>
      <Title>{title}</Title>
      <PreviewCards>
        {sortedItems.map((item) => (
          item.id && (
            <PreviewCardContainer key={item.id} >
              <CheckButton  style={{borderColor: darkMode && '#E5E4E2', borderBottomColor: darkMode && '#E5E4E2'}}>
                {courseHistory[item.id].completed ? (
                  <CompletedDiv>
                    <CompletedText>Completed</CompletedText>
                  </CompletedDiv>
                ) : (
                  <ProgressBar current={courseHistory[item.id].index} total={courseHistory[item.id].length} />
                )}
                <FontAwesomeIcon icon={faCheckCircle} style={{cursor: 'pointer'}}/>
              </CheckButton>
              <PreviewCard
                style={{borderColor: darkMode && '#E5E4E2', backgroundColor: darkMode && '#E5E4E2'}}
                onClick={() => passData(item)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PreviewCardImageContainer>
                  <CardImage src={item.image} alt={item.header} />
                </PreviewCardImageContainer>
                <TextHolder>
                  <TextHeader className='monoRoman'>{item.header}</TextHeader>
                  {!item.courseStatus && (
                    <TextHeader tw="text-red-400">Coming Soon!</TextHeader>
                  )}
                  <TextContent className='nokora '>{item.content}</TextContent>
                </TextHolder>
              </PreviewCard>
            </PreviewCardContainer>
          )
        ))}
      </PreviewCards>
    </Container>
  );
};

export default CurrentlyLearningSection;

import React, { useState } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css, ThemeConsumer } from "styled-components/macro"; //eslint-disable-line
import { useEffect } from "react";

import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store/index";
import MainLandingPage from "MainLandingPage.js";
import Signup from "pages/Signup";
import Signin from "pages/Signin";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Pricing from "pages/Pricing";
import MyLearning from "pages/AllCourses";

import { authAction } from "store/authSlice";
import Content from "pages/Content";
import ThankYouPage from "ThankYouPage";
import AllCourses from "pages/AllCourses";
import Roadmap from "pages/Roadmap";
import RoadmapList from "components/learning/RoadmapList";
import socket from "socket";

import Support from "pages/Support";
import LoggedInHomePage from "pages/LoggedInHomePage";
import LiveCourses from "pages/LiveCourses";
import VideoUpload from "pages/VideoUpload";
import LandingPage from "pages/LandingPage";
import SalesModal from "components/SalesModal";
import ExclusiveModal from "components/ExclusiveModal";
import DocumentPage from "pages/DocumentPage";
import AllBooks from "pages/AllBooks";

export default function RouteApp() {
    const token = localStorage.getItem('token');

    const status = useSelector(state => state.auth.active)
    const email = useSelector(state => state.auth.email)
    const foreverPackage = useSelector(state => state.auth.foreverPackage)

    const dispatch = useDispatch()
    useEffect(() => {
      fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/auth/authState', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data)
          if (data.email) {
            dispatch(
              authAction.login({
                email: data.email,
                token: token, // Use the token from the Redux store
                foreverPackage: data.foreverPackage
              })
            );
          } else {
            dispatch(authAction.logout());
          }
        });
      
        socket.on('userSignIn', (data) => {
          // Check if the signed-in user is the same as the current user
          if (data.email === email) {
            // Perform the necessary actions, e.g., force logout
            dispatch(authAction.logout());
          }
        });
      
        // Clean up the listener when the component unmounts
        return () => {
          socket.off('userSignIn');
        };
    }, [dispatch, token, email]);

    useEffect(() => {
      console.log(foreverPackage)
    })

  return (
    <>
      <Router>
        <Routes>  
          <Route path="/" element={!status ? <MainLandingPage/>: <LoggedInHomePage/>} />
          {/* <Route path="/signup" element={!status && <ThankYouPage />} /> */}
          <Route path="/signup" element={!status && <Signup />} />
          <Route path="/signin" element={!status && <Signin />} />
          <Route path="/pricing" element={!status && <Pricing />} />
          <Route path="/content" element={status ? <Content/>: <Signin/>} />
          <Route path="/roadmap" element={<Roadmap/>} />
          <Route path="/roadmapList" element={status ? <RoadmapList/>: <Signin/>} />
          <Route path="/support" element={status ? <Support/>: <Signin/>} />
          <Route path="/project" element={status ? <VideoUpload/>: <Signin/>} />
          <Route path="/documents" element={<DocumentPage />} foreverPackage={foreverPackage}/>
          <Route path="/books" element={<AllBooks /> } foreverPackage={foreverPackage}/>

          <Route path="/all" element={<AllCourses/>} />
        </Routes>
      </Router>

    </>
  );
}

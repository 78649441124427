import React, { useState, useEffect, useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import { useSelector } from "react-redux";
import Courses from "components/learning/MyCourses.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import { AiFillCaretDown } from "react-icons/ai";
import Modal from "components/portal/Modal";
import ProjectModalOne from "components/portal/Modal";
import { async } from "@firebase/util";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { motion } from "framer-motion";
import LoadingScreen from "components/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { DarkModeContext } from "components/DarkModeContext";

const TitleContainer = tw.button`flex justify-around items-center w-full mb-5 mt-5 bg-white border-gray-300 border shadow p-5`;
const Content = tw.p`text-gray-700 text-base`;
const TitleDiv = tw.div`flex items-center flex-col`;
const Title = tw.h1`font-bold text-2xl mr-2`;
const Row = tw.div`flex`;
const PreviewCards = tw.button`flex flex-wrap flex-col w-full border-l-2 p-5 mx-10`;
const PreviewCardContainer = tw.div`mx-auto md:mx-0 max-w-4xl w-full pr-12 hover:text-gray-700 `;
  const PreviewCard = tw(motion.a)`bg-white block shadow-raised duration-300 hover:bg-gray-200 hover:border-black duration-500	flex-row flex border`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0 flex-1 p-5`;
const CardImage = tw.img`flex items-center justify-center w-full h-full object-cover border border-gray-200 rounded-md`;
const TextHolder = tw.div`flex text-left flex-col m-3 h-32 lg:h-48 md:h-48 flex-1`;
const TextHeader = tw.h1`text-lg font-bold`;
const TextContent = tw.p`mt-1 text-gray-700 text-base overflow-auto`;
const PreviewButton = tw(PrimaryButtonBase)`w-full rounded-b-lg rounded-t-none py-3 bg-blue-500 font-semibold`;
const CouresTag = tw.h2`text-black text-base text-sm`;

export default () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState([]);
  const [loading, setLoading] = useState(true);
  const data = useLocation();
  const courseID = data.state.courses;
  const token = useSelector((state) => state.auth.token);

  const getData = async () => {
    fetch("https://usitebackend-36ad67a8d6c6.herokuapp.com/roadmap/list", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ courseID: courseID }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCourse(data);
      });
  };

  useEffect(() => {
    getData().then(() => {
      setLoading(false);
    });
  }, []);

  const passData = (p) => {
    navigate("/content", { state: p });
  };

  if (loading) {
    return <LoadingScreen />;
  }

  const {darkMode} = useContext(DarkModeContext)

  return (
    <AnimationRevealPage>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <TitleContainer
              style={{backgroundColor: darkMode && '#E5E4E2', borderColor: darkMode && 'black'}}
            >
            <TitleDiv>
              <Title style={{color: darkMode && 'black'}} >{data.state.title}</Title>
              <Content className="nokora">{data.state.description}</Content>
            </TitleDiv>
          </TitleContainer>

          <PreviewCards>
            {course.map((item) => (
              <PreviewCardContainer key={item}>
                <PreviewCard
                  initial="rest"
                  whileHover="hover"
                  onClick={(event) => passData(item)}
                  target="_blank"
                  style={{backgroundColor: darkMode && '#E5E4E2', borderColor: darkMode && 'black'}}
                >
                  <PreviewCardImageContainer>
                    <CardImage draggable="false" src={item.image} />
                  </PreviewCardImageContainer>
                  <TextHolder>
                    <CouresTag className="nokora">COURSE</CouresTag>
                    <TextHeader className="nokora" style={{color: darkMode && 'black'}}>{item.header}</TextHeader>
                    {!item.courseStatus && (
                      <TextHeader tw="text-red-400">Coming Soon!</TextHeader>
                    )}
                    <TextContent className="nokora">{item.content}</TextContent>
                  </TextHolder>
                </PreviewCard>
              </PreviewCardContainer>
            ))}
          </PreviewCards>

          <Footer />
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  text-align: center;
  width: 300px; /* Adjust the width as needed */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

const RatingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Star = styled.span`
  font-size: 24px;
  cursor: pointer;
  color: ${({ filled }) => (filled ? "#FFD700" : "#ccc")};
  margin: 0 5px;
`;

const FeedbackText = styled.textarea`
  width: 100%;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
`;

const SubmitButton = styled.button`
  background-color: #167BE7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const ButtonHolder = tw.div`flex justify-evenly`

const Message = styled.div`
  font-size: 1rem;
  margin-bottom: 20px;
  color: ${({ success }) => (success ? "#4CAF50" : "#f44336")};
`;

const FeedbackModal = ({ onClose, onSubmit, message }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  const handleRatingClick = (value) => {
    setRating(value);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = () => {
    // Perform any validation you need here
    onSubmit(feedback, rating);
  };

  return (
    <ModalWrapper>
      <h2>Feedback Modal</h2>
      <RatingWrapper>
        {[1, 2, 3, 4, 5].map((value) => (
          <Star
            key={value}
            filled={value <= rating}
            onClick={() => handleRatingClick(value)}
          >
            ★
          </Star>
        ))}
      </RatingWrapper>
      <FeedbackText
        placeholder="Enter your feedback..."
        value={feedback}
        onChange={handleFeedbackChange}
      />
      {message && (
        <Message success={message.success}>
          {message.message}
        </Message>
      )}
      <ButtonHolder>
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </ButtonHolder>
    </ModalWrapper>
  );
};

export default FeedbackModal;
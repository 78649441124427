import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro"; 
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";

import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import Header from "Header";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-blue-500 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-xl text-xl text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {

  return (
    <AnimationRevealPage enabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header/>
          <HeroRow>
            <Heading>Thank You!</Heading>
            <Description tw="mt-12">អរគុណដែលបានមកកាន់ website ហេីយជួយ test!</Description>
            <div tw="mt-12 text-center">
              Video Lecture ផ្សេងៗនឹងត្រូវបាន upload និងប្រេីប្រាស់បានក្នុងពេលឆាប់ៗ
            </div>
            <div tw="mt-12 text-center">
              ជួយ follow                 <a
                  href="https://facebook.com/uddom1234"
                  className="twitter-follow-button"
                  data-size="large"
                  data-show-count="true"
                >FaceBook </a> និង <a
                href="https://www.youtube.com/channel/UCaUSGMTwS1DXBysr9_z0-Qw"
                className="twitter-follow-button"
                data-size="large"
                data-show-count="true"
              >
                YouTube
              </a> ខ្ញុំផង និងជួយចែករំលែកពីខ្ញុំផង អរគុណ!

            </div>
          </HeroRow>
          <MiniCenteredFooter/>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

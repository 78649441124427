import React, { useState } from 'react';
import tw from 'twin.macro';

const ModalOverlay = tw.div`
  fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[1000]
`;

const ModalContainer = tw.div`
  bg-white p-3 rounded-lg shadow-md relative w-96 flex items-center justify-center flex-col
`;

const Question = tw.p`
  text-lg mb-4
`;

const Message = tw.p`
  text-lg my-4
`;

const Button = tw.button`
  m-2 px-4 py-2 bg-white text-black rounded hover:bg-blue-200 active:bg-blue-300 focus:outline-none border border-black
`;

const CloseButton = tw.button`
  w-full flex items-center justify-center mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 active:bg-red-700 focus:outline-none
`;

const CTAButton = tw.a`
  w-full flex items-center justify-center mt-8 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 active:bg-green-700 focus:outline-none
`;

const RegisterButton = tw.a`
  w-full flex items-center justify-center mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 active:bg-red-700 focus:outline-none
`;

const AnswerContainer = tw.div`
    flex flex-wrap justify-center w-full items-center text-center
`

function SalesModal({ onClose }) {
  const [answer1, setAnswer1] = useState('');
  const [answer2, setAnswer2] = useState('');
  const [step, setStep] = useState(1);

  const getRecommendation = () => {
    if (answer2 === 'yes') {
      onClose();
    } 
    if (answer1 === 'web dev' && answer2 === 'no') {
      return 'ចឹងខាងខ្ញុំ recommend បងប្រើផែនទី "Beginner" ហើយចប់រៀនពីផែនទី "Full Stack Web Development"';
    } else if (answer1 === 'game dev' && answer2 === 'no') {
      return 'ចឹងខាងខ្ញុំ recommend បងរៀន C# ហើយចាំចូល Game Dev ហើយបើបងអត់គ្រឹះ ខ្ញុំ recommend បងរៀនផែនទី "Beginner" សិន';
    } else if (answer1 === 'app dev' && answer2 === 'no') {
      return 'ចឹងខាងខ្ញុំ recommend បងរៀនពី Flutter និង Firestore តែដំបូងខ្ញុំ recommend បងចាប់ Java ឲយល់ពី OOP language និងភាសាដំបូងសិន';
    } else if (answer1 === 'money' && answer2 === 'no') {
      return 'ចឹងខាងខ្ញុំ recommend បងរៀនខាងបង្កើត website ឬ app ដោយសារតែចំណូលយើងអាចរកបានពី freelancing ឬបង្កើតផលិតផលឌីជីថលខ្លួនឯងបាន';
    } else if (answer1 === 'code' && answer2 === 'no') {
        return 'ចឹងខាងខ្ញុំ recommend បងរៀនផែនទី "Beginner"'
      }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer>
        {step === 1 && (
          <>
            <Question className='nokora'>តើបងចង់រៀនអ្វីដែល?</Question>
            <AnswerContainer >
              <Button className='nokora' onClick={() => { setAnswer1('web dev'); setStep(2); }}>បង្កើត website</Button>
              <Button className='nokora' onClick={() => { setAnswer1('code'); setStep(2); }}>ចង់ចេះកូដ</Button>
              <Button className='nokora' onClick={() => { setAnswer1('app dev'); setStep(2); }}>បង្កើត app</Button>
              <Button className='nokora' onClick={() => { setAnswer1('money'); setStep(2); }}>រកចំណូលជាមួយកូដ</Button>
              <Button className='nokora' onClick={() => { setAnswer1('business'); setStep('business'); }}>រៀនពី business</Button>
              <RegisterButton className="nokora" href='https://t.me/uddomsite' target="_blank">ចុះឈ្មោះ</RegisterButton>

            </AnswerContainer>
          </>
        )}
        
        {step === 2 && (
          <>
            <Question>បងធ្លាប់រៀនកូដពីមុនទេ?</Question>
            <AnswerContainer className="mb-4">
              <Button className='nokora' onClick={() => { setAnswer2('yes'); setStep(3); }}>ធ្លាប់</Button>
              <Button className='nokora' onClick={() => { setAnswer2('no'); setStep(3); }}>អត់ធ្លាប់</Button>
              <RegisterButton className="nokora" href='https://t.me/uddomsite' target="_blank">ចុះឈ្មោះ</RegisterButton>

            </AnswerContainer>
          </>
        )}

        {step === 'business' && (
          <AnswerContainer>
            <Message className='nokora'>FREE ប្រឹក្សា 1 ទល់ 1 ជាមួយ Uddom Pang លើអាជីវកម្មលោកអ្នក</Message>
            <CTAButton className='nokora' href='https://t.me/upangconsult' target="_blank">ប្រឹក្សា FREE</CTAButton>

          </AnswerContainer>
        )}

        {step === 3 && (
          <AnswerContainer>
            <Message>{getRecommendation()}</Message>
            <RegisterButton className="nokora" href='https://t.me/uddomsite' target="_blank">ចុះឈ្មោះ</RegisterButton>

          </AnswerContainer>
        )}
            <CloseButton className="nokora" onClick={onClose}>បិទ</CloseButton>
      </ModalContainer>
    </ModalOverlay>
  );
}

export default SalesModal;

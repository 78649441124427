import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";

export default () => {

  return (
    <AnimationRevealPage>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <Pricing />
          <Footer/>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

import React, { useState } from "react";
import styled from "styled-components";
import { FaPrint } from "react-icons/fa"; // Assuming an icon for completion
import { BeatLoader } from "react-spinners";

const CompletionButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 175px;
  z-index: 1000;
`;

const CompletionButton = styled.button`
  background-color: #ecc94b;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CompletionIcon = styled(FaPrint)`
  margin-right: 10px;
`;


const CompletedButtonComponent = ({ user, name }) => {
  const [isCompletionOpen, setIsCompletionOpen] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const onSubmitCompletion = async () => {
    setIsLoading(true); // Start loading
    // Logic for handling completion
    try {
      const response = await fetch('http://localhost:4000/history/print', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: user, name: name }),
      });

      // ... handle the response
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <CompletionButtonWrapper>
        {isLoading ? (
            <div>
                <BeatLoader color="white" />
            </div> // Here you can use a more sophisticated loading animation
        ) : (
            <CompletionButton onClick={onSubmitCompletion}>
                <CompletionIcon size={20} /> Print Certificate
            </CompletionButton>
        )}
    </CompletionButtonWrapper>
  );
};

export default CompletedButtonComponent;

import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import { Container, Content2Xl } from "components/misc/Layouts";
import Header from "Header";
import MiniCenteredFooter from "components/footers/MiniCenteredFooter";
import { useSelector } from "react-redux";
import axios from "axios";
import ExclusiveModal from "components/ExclusiveModal";


const Title = styled.h1`
  ${tw`text-3xl font-bold mb-4 text-gray-900`}
`;

const BookList = styled.ul`
  ${tw`grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 px-4`}
`;

const BookCard = styled.li`
  ${tw`flex flex-col bg-white rounded-lg shadow-lg overflow-hidden`}
`;

const BookCardContent = styled.div`
  ${tw`p-4`}
`;

const BookTitle = styled.h2`
  ${tw`text-lg font-semibold mb-2`}
`;

const BookAuthor = styled.p`
  ${tw`text-gray-600`}
`;

const BookImage = styled.img`
  ${tw`w-full h-full object-cover object-center`}
`;

const DownloadButton = styled.button`
  ${tw`mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
`;

const SearchBar = styled.input`
  ${tw`mb-8 w-full py-2 px-4 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500`}
`;

// Component
export default function AllBoks () {
    const token = useSelector(state => state.auth.token); // Get the token from the Redux store
    const [books, setBooks] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const foreverPackage = useSelector(state => state.auth.foreverPackage)

    useEffect(() => {

        fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/books/', {
            headers: {
            'Authorization': `Bearer ${token}`,
            },
        })
            .then((response) => {
            return response.json();
            })
            .then((data) => {
                setBooks(data)
        
            })
            .catch((error) => console.log(error));
    }, [])

  const [searchQuery, setSearchQuery] = useState("");

//   Filter books based on search query
  const filteredBooks = books.filter(
    book =>
      book.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      book.author.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleDownload = book => {
    if (token && foreverPackage) { 
    axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/books/${book.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }).then(response => {
      const downloadUrl = response.data.url;

      // Create a temporary anchor element
      const anchor = document.createElement('a');
  
      // Set the href attribute to the book's URL
      anchor.href = downloadUrl;
  
      // Set the download attribute to force the browser to download the file instead of navigating to it
      if (downloadUrl.toLowerCase().endsWith('.pdf')) {
        anchor.setAttribute('download', `${book.title}.pdf`);
      }
  
      // Append the anchor element to the document body
      document.body.appendChild(anchor);
  
      // Trigger a click event on the anchor element to start the download
      anchor.click();
  
      // Remove the anchor element from the document body
      document.body.removeChild(anchor);
    });
  } else {
    setShowModal(true)
  }

  }
  



  return (
    <AnimationRevealPage>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8" className="mono">
        <Content2Xl>
          <Header />
          <Title>All Books</Title>
          <SearchBar
            type="text"
            placeholder="Search books..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <BookList className="mono">
            {filteredBooks.map(book => (
              <BookCard key={book.id}>
                <BookImage src={book.image} alt={book.title} />
                <BookCardContent>
                  <BookTitle>{book.title}</BookTitle>
                  <BookAuthor>by {book.author}</BookAuthor>
                  <DownloadButton onClick={() => handleDownload(book)}>Download</DownloadButton>
                </BookCardContent>
              </BookCard>
            ))}
            {showModal && <ExclusiveModal onClose={() => setShowModal(false)} />}

          </BookList>
          <MiniCenteredFooter />
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};


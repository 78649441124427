import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { authAction } from 'store/authSlice';
import { DarkModeContext } from './DarkModeContext';

const ToggleButton = styled.button`
  background-color: ${({ themeMode }) => themeMode === 'light' ? '#f0f0f0' : '#2c3e50'};
  border: 1px solid ;
  border-color: ${({ themeMode }) => themeMode === 'light' ? 'black' : 'white'};

  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  width: 80px;
  height: 40px;
  padding: 5px;
  font-size: 12px;
  color: white;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Slider = styled.div`
  background-color: ${({ themeMode }) => themeMode === 'light' ? '#2c3e50' : '#f0f0f0'};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ themeMode }) => themeMode === 'light' ? 'translateX(0)' : 'translateX(40px)'};
`;

const DarkModeToggle = () => {

  // Use useState to manage the darkMode state
  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  const toggleDarkModeHandler = () => {
    setDarkMode(!darkMode);
  };




  return (
    <ToggleButton themeMode={darkMode ? 'dark' : 'light'} onClick={toggleDarkModeHandler}>
      <Slider themeMode={darkMode ? 'dark' : 'light'} />
    </ToggleButton>
  );
};

export default DarkModeToggle;

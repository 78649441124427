import React, { useContext, useRef } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Courses from "components/learning/MyCourses.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import { AiFillCaretDown, AiFillWarning } from "react-icons/ai";
import Modal from "components/portal/Modal";
import ProjectModalOne from "components/portal/Modal";
import { async } from "@firebase/util";
import LoadingScreen from "components/LoadingScreen";
import CustomVideoPlayer from "components/CustomVideoPlayer";
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import FeedbackButtonComponent from "components/Feedback";
import CompletedButtonComponent from "components/Completed";
import { DarkModeContext } from "components/DarkModeContext";






const TitleDiv = tw.div`flex items-center`;
const Title = tw.h1`font-bold text-2xl mr-2`;
const Row = tw.div`flex`;
const ContainerRow = tw(Row)`flex-col lg:flex-row max-w-screen-2xl mx-auto flex-wrap`;
const Column = tw.div``;
const VideoColumn = tw(Column)`mx-auto lg:mr-0 relative`;
const TitlesColumn = tw(Column)`mx-auto lg:mr-0 relative w-full`;
const ResourcesButton = tw.a``;
const CourseTitle = tw.p`border p-5 font-bold flex-row whitespace-nowrap`;
const Disclaimer = tw.p`border text-black bg-yellow-500 p-5 font-bold flex-row flex items-center whitespace-nowrap`;

const LectureReveal = styled.button`
  ${tw`border p-5 font-bold flex-row w-full text-left flex flex-row`}
  overflow: hidden;
  max-height: ${({ isRevealed }) => (isRevealed && '1000px')};
  transition: max-height 0.3s ease-in-out;
`;

const DescriptionContainer = tw.div`w-full p-5`;
const DescriptionHolder = tw.div`border border-gray-500 w-full overflow-y-auto p-5`;
const DescriptionTitle = tw.h1`font-bold text-lg`;
const Description = tw.p`lg:text-xl text-lg`;
const PercentageSpan = tw.span`text-green-500 font-bold text-lg`;





export default () => {
  const [currentLesson, setCurrentLesson] = useState(0); // Add current lesson state

  const data = useLocation();
  const [url, setUrl] = useState(data.state.overview);
  const contentList = ['Overview', 'Setup'];
  const contentUrl = [data.state.overview, data.state.setup];

  const [isOpen, setIsOpen] = useState(false);
  const content = [];
  const [resources, setResources] = useState([]);
  const [introductory, setIntroductory] = useState([]);
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [length, setLength] = useState();
  const videoRef = useRef(null);
  const [played, setPlayed] = useState(0);
  const [resumeTime, setResumeTime] = useState(0); 
  const [completed, setCompleted] = useState(false); // Add completed state
  const completionThreshold = 0.9; 
  
  const token = useSelector((state) => state.auth.token); // Get the token from the Redux store

  const getData = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the token in the headers
      },
    };
    fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/courses/${data.state.id}`, requestOptions)
      .then(response => response.json())
      .then(data => setLectures(data));

    fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/resources/${data.state.id}`, requestOptions)
      .then(response => response.json())
      .then(data => setResources(data));
  };

  let email = useSelector((state) => state.auth.email);
  const user = email;

  useEffect(() => {
    if (videoRef.current) {
      // Check if resumeTime is a finite number before setting it
      if (typeof resumeTime === 'number' && isFinite(resumeTime)) {
        videoRef.current.currentTime = resumeTime;
      } else {
        // Handle the case where resumeTime is not a valid number
        console.error('Invalid resumeTime:', resumeTime);
        // You may want to set a default value or handle this error differently
      }
    }
  }, [resumeTime]);
  const {darkMode} = useContext(DarkModeContext)
  const ContentButton = styled.button(({ isActive }) => [
    tw`w-full text-left p-5 border border-gray-300 transition-colors duration-300 ease-in-out hover:bg-blue-100 border`,
    isActive ? tw`bg-blue-400 text-white` : tw`bg-white text-black`,
    darkMode && tw` border-black hover:bg-gray-400 bg-gray-300`,
    isActive && darkMode && tw`bg-gray-500 text-white`,
  ]);

  const TitleContainer = styled.button(({}) => [
    tw`hover:bg-blue-700 transition duration-300 ease-in-out hover:shadow flex justify-around items-center w-full h-16 mb-5 mt-5 bg-[#167BE7] text-white`,
    darkMode && tw` border-black hover:bg-gray-400 bg-gray-300 text-black`,
  ])
    ;

    const HeaderTitle = styled.div(({}) => [
      tw`border font-bold w-full text-left p-5 bg-white shadow underline underline-offset-2`,
      darkMode && tw` border-black bg-gray-300 underline text-black underline-offset-2`,
    ])


  const getHistory = async () => {
    fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: user, id: data.state.id }),
    })
      .then(response => response.json())
      .then(data => {
        if(!data.error) {
          if(typeof data.link === 'string') {
            setUrl(data.link)
            setCurrentLesson(data.index)  
            setLength(data.length)
            setResumeTime(data.resumeTime)
          } else {
            setUrl(data)
          }
        }
      });
  };

  useEffect(() => {
    getData()
      .then(getHistory)
      .then(() => setLoading(false));
  }, []);

  const [showLecture, setShowLecture] = useState(true);


  const revealLecture = () => {
    setShowLecture(!showLecture);
  };


  const buttonHandler = async (params, index) => {
    const lectureLength =  lectures.length-1;

    setResumeTime(0)


    await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: user, id: data.state.id, link: params, index: index, length: lectures.length-1, time: new Date().toISOString(), resumeTime: 0 }),
    });

    setUrl(params);
    setCurrentLesson(index); // Update the current lesson state
  };

  useEffect(() => {
    if (currentLesson === length) {
      const postCompletion = async () => {
        try {
          const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/complete', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user: user, id: data.state.id }),
          });
          if (response.ok) {
            // Handle successful completion
            setCompleted(!completed)
          } else {
            // Handle errors
          }
        } catch (error) {
          console.error('Error:', error);
          // Handle fetch errors
        }
      };
  
      postCompletion();
    }
  }, [currentLesson, length, user, data]); // Include all external variables used in the effect
  

  for (let i = 0; i < contentUrl.length; i++) {
    if (contentUrl[i]) {
      content.push(
        <ContentButton
          className="monoRoman"
          key={i}
          onClick={() => buttonHandler(contentUrl[i], i)}

          isActive={url === contentUrl[i]} // Check if the URL matches or if it's the first button and URL is not set
        >
          {contentList[i]}
        </ContentButton>
      );
    }
  }

  const saveResumeTimeToBackend = async (timeInSeconds) => {
    try {
      // Send the resume time to your backend API for storage
      const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/saveSecond', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include any authentication headers if required
        },
        body: JSON.stringify({ user: user, id: data.state.id, resumeTime: timeInSeconds }),
      });

      if (response.ok) {
        console.log('Resume time saved successfully.');
      } else {
        console.error('Failed to save resume time.');
      }
    } catch (error) {
      console.error('Error while saving resume time:', error);
    }
  };

  useEffect(() => {
    const saveInterval = setInterval(() => {
      // Check if the videoRef exists and if it's playing
      if (videoRef.current && !videoRef.current.paused) {
        const currentTime = Math.floor(videoRef.current.currentTime);

        // Ensure that the currentTime is greater than 0 before saving
        if (currentTime > 0) {
          // Save the current playback time to the backend
          saveResumeTimeToBackend(currentTime);
        }
      }
    }, 30000); // 30 seconds

    // Cleanup the interval when the component unmounts
    return () => clearInterval(saveInterval);
  }, []);

  const calculatePercentageCompletion = () => {
    if (length > 0) {
      const percentage = ((currentLesson + 1) / length) * 100; // Adding 1 to currentLesson to make it 1-based instead of 0-based
      return percentage.toFixed(2); // Round to one decimal place
    } else {
      return 0;
    }
  };

  const handleVideoEnded = async () => {
    // Check if there's a next video in the list
      const nextVideoIndex = currentLesson + 1;

      await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: user, id: data.state.id, link: lectures[nextVideoIndex].link, index: nextVideoIndex, length: lectures.length-1, time: new Date().toISOString() }),
      });
      
      // Update the video URL and current lesson index
      setUrl(lectures[nextVideoIndex].link);
      setCurrentLesson(nextVideoIndex);

  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <AnimationRevealPage>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <TitleContainer onClick={() => setIsOpen(true)}>
            <TitleDiv>
              <Title className="monoRoman">{data.state.header}</Title>
              <AiFillCaretDown />
            </TitleDiv>
          </TitleContainer>
          {data.state.schedule && (
            <Modal open={isOpen} onClose={() => setIsOpen(false)} schedule={data.state.schedule} />
          )}
          <ContainerRow>
            <VideoColumn style={{ flex: 3 }}>
            <video
              ref={videoRef}
              src={url}
              autoPlay
              preload="auto"
              controls
              controlsList="nodownload"
              style={{ flex: 1 }}
              onProgress={({ played }) => setPlayed(played)}
              onEnded={handleVideoEnded}
              onLoadedMetadata={() => {
                if (videoRef.current) {
                  // Check if resumeTime is a finite number before setting it
                  if (typeof resumeTime === 'number' && isFinite(resumeTime)) {
                    videoRef.current.currentTime = resumeTime;
                  } else {
                    // Handle the case where resumeTime is not a valid number
                    console.error('Invalid resumeTime:', resumeTime);
                    // You may want to set a default value or handle this error differently
                  }
                }
              }}

            ></video>

              <DescriptionContainer>
                <DescriptionHolder>
                  <DescriptionTitle className="monoRoman">About this course</DescriptionTitle>
                  <Description className="nokora">{data.state.content}</Description>
                </DescriptionHolder>
              </DescriptionContainer>
            </VideoColumn>
            <TitlesColumn style={{ flex: 1 }}>
            <Disclaimer className="nokora">
              វីឌីអូយកចែកចាយនឹងជាប់តាមផ្លូវច្បាប់! <AiFillWarning size={20} style={{marginLeft: 10}}/>
            </Disclaimer>

              <CourseTitle className="monoRoman">Course content: <PercentageSpan>{calculatePercentageCompletion()}%</PercentageSpan></CourseTitle>
              {content}
              {data.state.discussionLink && (
                <ResourcesButton target="_blank" href={data.state.discussionLink}>
                  <ContentButton className="monoRoman">Telegram Group</ContentButton>
                </ResourcesButton>
              )}
              {resources.map((item) => (
                <ResourcesButton target="_blank" href={item.link} key={item.title}>
                  <ContentButton className="monoRoman">{item.title}</ContentButton>
                </ResourcesButton>
              ))}
              <LectureReveal onClick={revealLecture} isRevealed={showLecture} className="monoRoman">
                Lectures
                <AiFillCaretDown style={{ marginLeft: 4, alignSelf: 'center' }} />
              </LectureReveal>
              <div style={{ maxHeight: showLecture ? '1000px' : '0', overflow: 'auto', transition: 'max-height 0.3s ease-in-out' }}>

              {showLecture &&
                lectures.map((item, index) =>
                  item.header ? (
                    <HeaderTitle key={index} className="monoRoman">{item.name}</HeaderTitle>
                  ) : item.file ? (
                    <div key={index}>
                      <ResourcesButton target="_blank" href={item.link}>
                        <ContentButton
                          onClick={() => buttonHandler(item.link, index)}
                          isActive={currentLesson === index && url === item.link}
                          className="monoRoman"
                        >
                          {item.name}
                        </ContentButton>
                      </ResourcesButton>
                    </div>
                  ) : (
                    <ContentButton
                      key={index}
                      onClick={() => buttonHandler(item.link, index)}
                      isActive={currentLesson === index && url === item.link}
                      className="monoRoman"
                    >
                      {item.name}
                    </ContentButton>
                  )
                )}
              </div>
            </TitlesColumn>
          </ContainerRow>
          <FeedbackButtonComponent id={data.state.id} user={user}/>
          {/* {completed && <CompletedButtonComponent name={data.state.header} user={user}/>} */}

          <Footer />
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

import React from 'react';
import tw from 'twin.macro';
import { SectionHeading as HeadingBase } from 'components/misc/Headings';
import { SectionDescription as DescriptionBase } from 'components/misc/Typography';

// Styled components
const SectionContainer = tw.div`bg-gray-200 p-5`;
const SectionHeading = tw(HeadingBase)`text-blue-800`;
const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl text-2xl`;

// New styled component for the partner icon
const PartnerIcon = tw.div`flex justify-evenly items-center h-32 w-full`;
const PartnerIconImage = tw.img`h-128`;

const PartnershipSection = () => {
  return (
    <SectionContainer>
      <SectionHeading className="nokora">Partnership/ដៃគូសហការ</SectionHeading>
      <SectionDescription className="nokora">
        សិស្សនៅលើ Uddom Pang នឹងមានសិទ្ធិពិសេសជាមួយដៃគូសហការ
      </SectionDescription>
      <PartnerIcon>
        <PartnerIconImage src="https://firebasestorage.googleapis.com/v0/b/uddompang-dfd58.appspot.com/o/KampuFreelancer-Logo2_Y.png?alt=media&token=30b5ad92-d9b5-45e6-bdf4-ad290c24087c"  />
      </PartnerIcon>
    </SectionContainer>
  );
};

export default PartnershipSection;

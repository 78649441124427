import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/mockup.png";
import logo from "images/logo.png";
import { LogoOfficial } from "components/headers/light";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAction } from "store/authSlice";
import { useSelector } from "react-redux";
const Container = tw(ContainerBase)`min-h-screen bg-blue-500 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow-lg sm:rounded-lg flex flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 flex-1 sm:p-12 justify-center mt-20 `;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;
const LogoContainer = tw.div`justify-center flex`;
const Signup = styled.a`
  ${tw`mt-5 tracking-wide font-semibold border-black border bg-white text-black w-full py-4 rounded-lg hover:bg-blue-300 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  box-shadow: 0.2rem 0.2rem black;

  .text {
    ${tw`ml-3 text-lg`}
  }
`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  box-shadow: 0.2rem 0.2rem black;

  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.a`sm:rounded-r-lg flex-1 bg-blue-100 text-center hidden lg:flex justify-center items-center p-10 relative`;
const IllustrationImage = styled.div`
  ${tw`m-12 xl:m-16 w-full `}
`;
const ImageProps = styled.img`
${tw`border-white border-2 bg-contain h-80 transform transition-all duration-300 ease-in-out `}
box-shadow: 0.5rem 0.5rem black, -0.5rem -0.5rem #ccc;

`

const RegisterButton = styled.div`
  ${tw`absolute bottom-1/2 left-1/2 transform -translate-x-1/2 bg-white border border-black text-black rounded py-2 px-4 shadow-lg transition-all duration-300 ease-in-out z-[100] hover:bg-gray-300`}
  box-shadow: 0.1rem 0.1rem #ccc, -0.1rem -0.1rem #ccc;

`;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "ចូលគណនីដើម្បីចាប់ផ្តើមរៀន",
  submitButtonText = "Login",
  tosUrl = "#",
  signInUrl = "/signup"
}) => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const token = useSelector((state) => state.auth.token); // Get the token from the Redux store
  const email = useSelector((state) => state.auth.email); // Get the token from the Redux store

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [error, setError] = useState();

  const login = async (event) => {
    event.preventDefault();
  
    if (password.length < 8) {
      setError('Password must be 8 characters long!');
    } else if (userName.length < 8) {
      setError('Username must be 8 characters long!');
    } else {
      try {
        const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/auth/signin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userName, password }),
        });
        const data = await response.json();
        if (response.ok) {
          localStorage.setItem('token', data.token);

          // Store the token in a state variable or a context  
          dispatch(
            authAction.login({
              email: data.email,
              token: data.token,
              foreverPackage: data.foreverPackage
            })
          );

          navigate('/all');
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };
  

  return(
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoContainer>
          <LogoOfficial href="/">
              <img src={logo} alt="" />
          </LogoOfficial>
          </LogoContainer>

          <MainContent>
            <Heading className="nokora">{headingText}</Heading>
            <FormContainer>
              <Form>
                <Input type="email" placeholder="Email"
                        onChange={(event)=>setUserName(event.target.value)}
                        value={userName}/>
                <Input type="password" placeholder="Password"
                        onChange={(event)=>setPassword(event.target.value)}
                        value={password}/>
                <SubmitButton type="submit" onClick={login}>
                  <span className="text mono">{submitButtonText}</span>
                </SubmitButton>
                <p tw="mt-6 text-red-600 text-center">{error}</p>
      
                <Signup target="_blank" href="https://t.me/uddomsite">
                  <span className="nokora">បង្កើតគណនី</span>
                </Signup>

                <p className="nokora" tw="mt-8 text-gray-600 text-center text-lg">
                  បើអត់ទាន់មាន អាចបង្តើគណនីជាមួយខាងខ្ញុំ ចុចលើ{" "}
                  <a className="nokora" href="https://t.me/uddomsite" target="_blank" tw="border-b border-gray-500 border-dotted">
                    បង្តើតណនី
                  </a>
                </p>
              </Form>
            </FormContainer>
          </MainContent>
        </MainContainer>
        <IllustrationContainer href="https://t.me/uddomsite" target="_blank">
          <RegisterButton className="mono">Register Now</RegisterButton>
          <ImageProps src={illustrationImageSrc} />
        </IllustrationContainer>

      </Content>
    </Container>
  </AnimationRevealPage>
)};

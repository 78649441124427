import React, { useState } from 'react';

function VideoUpload() {
  const [video, setVideo] = useState(null);
  const [email, setEmail] = useState('');

  const handleFileChange = (event) => {
    setVideo(event.target.files[0]);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !video) {
      console.error('Please enter email and select a video.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('video', video);
      formData.append('email', email);

      const options = {
        method: 'POST',
        body: formData,  // Send the formData directly
      };

      const response = await fetch('http://localhost:4000/upload', options);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
      } else {
        console.error('Failed to upload file.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div>
      <h1>Video Uploader</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <input type="file" accept="video/*" onChange={handleFileChange} />
        </div>
        <div>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <button type="submit">Upload</button>
      </form>
    </div>
  );
}

export default VideoUpload;

import React, { useContext, useState } from "react";
import styled from "styled-components";
import { FaCommentAlt } from "react-icons/fa";
import FeedbackModal from "./FeedbackModal";
import { DarkModeContext } from "./DarkModeContext";

const FeedbackButtonWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

const FeedbackButton = styled.button`
  background-color: #167BE7;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const FeedbackIcon = styled(FaCommentAlt)`
  margin-right: 10px;
`;

const ErrorMessage = styled.div`
  background-color: #f44336;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
`;

const SuccessMessage = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
`;

const FeedbackButtonComponent = ({ user, id }) => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const {darkMode} = useContext(DarkModeContext)
  const toggleFeedback = () => {
    setIsFeedbackOpen(!isFeedbackOpen);
  };

  const handleCloseModal = () => {
    setIsFeedbackOpen(false); // This function will close the modal
  };

  const onSubmit = async (feedback, rating) => {
    const requestData = {
      user: user,
      courseId: id,
      feedbackText: feedback,
      rating: rating,
    };
  
    try {
      const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/upload/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      if (response.status === 400) {
        // Handle 400 error
        setMessage(data);
      } else if (!response.ok) {
        // Handle other errors
        setMessage(data);
      } else {
        setMessage(data);
      }
      
  

    } catch (error) {
      // Handle any errors that occur during the fetch or JSON parsing
      console.error('Error:', error);
      setError(error.message); // Set the error message in the state
      // You can set an error state or display an error message to the user
    }
  };
  
  

  return (
    <FeedbackButtonWrapper>
      {isFeedbackOpen ? (
        <FeedbackModal isOpen={isFeedbackOpen} onClose={handleCloseModal} onSubmit={onSubmit} message={message}>
  
        </FeedbackModal>
      ) : (
        <FeedbackButton onClick={toggleFeedback} 
          style={{backgroundColor: darkMode && '#E5E4E2', color: darkMode && 'black'}}
        >
          <FeedbackIcon size={20} /> Feedback
        </FeedbackButton>
      )}
    </FeedbackButtonWrapper>
  );
};

export default FeedbackButtonComponent;

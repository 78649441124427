import React, { createContext, useState } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css, ThemeConsumer, ThemeContext } from "styled-components/macro"; //eslint-disable-line

import { Provider, useDispatch } from "react-redux";
import store from "./store/index";
import RouteApp from "./route";
import { DarkModeProvider } from "components/DarkModeContext";

// export const ModeContext = createContext(null);


export default function App() {
  // for setting theme feature
  // const [mode, setMode] = useState('light')

  // const toggleTheme = () => {
  //   setMode((curr) => (curr=== 'light'? 'dark' : 'light'))
  // }

  return (
    <>
      <GlobalStyles />
      <Provider store={store}>
      <DarkModeProvider>
      {/* <ModeContext.Provider value={{mode, toggleTheme}}> */}
        <RouteApp/>
      {/* </ModeContext.Provider> */}
      </DarkModeProvider>
      </Provider>
    </>
  );
}
import React, {useContext, useEffect, useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import { useSelector } from "react-redux";
import LoadingScreen from "components/LoadingScreen";
import { DarkModeContext } from "components/DarkModeContext";

const PreviewCards = tw.div`flex flex-wrap justify-center`;
const PreviewCardContainer = tw.div`mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 px-4 mb-24 hover:text-gray-700 flex-grow flex-shrink-0`;
const PreviewCard = tw(motion.a)`bg-white block rounded-b-lg shadow-raised duration-300 hover:-translate-y-3`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const CardImage = tw.img`flex items-center justify-center `
const TextHolder = tw.div`flex text-center flex-col m-3 lg:h-48 md:h-48`;
const TextHeader = tw.h1`text-lg font-bold`;
const TextContent = tw.p`mt-1 lg:text-lg text-lg`;
const PriceText = tw.p`mt-1 lg:text-2xl text-xl text-green-500 font-bold`;
const PreviewButton = tw(PrimaryButtonBase)`w-full rounded-b-lg rounded-t-none py-5 pl-10 bg-blue-500 `;
const FilterHolder = styled.div`
  ${tw`flex justify-center lg:justify-start xl:justify-start md:justify-start items-center w-full flex-wrap`}

`;


export default ({
  passData,
  searchTerm,
  showFilter,
  type
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const token = useSelector((state) => state.auth.token); // Get the token from the Redux store
  const [showFilters, setShowFilters] = useState(false);
  const [filter, setFilter] = useState(type ? type : '');
  
  const getData = async () => {
  

    fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/courses/`).then(response => {
      return (response.json())
    }).then(data => {
      setData(data)
    })
  }
  
  useEffect(()=> {
    getData().then(() => {
      setLoading(false)
    })
  }, [])

  if(loading) {
    return <LoadingScreen/>
  }

  const previewImageAnimationVariants = {
    rest: {
      backgroundPositionY: "100%"
    },
  };

  const filteredData = data.filter(item => {
    // Check for searchTerm match
    const searchTermMatch = !searchTerm ||
      (item.header && item.header.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.content && item.content.toLowerCase().includes(searchTerm.toLowerCase()));
  
    // Check for type match
    const typeMatch = !filter || (item.type && item.type.toLowerCase() === filter.toLowerCase());
  
    return searchTermMatch && typeMatch;
  });

  const {darkMode} = useContext(DarkModeContext)

  const FilterButton = styled.button`
    ${tw`mr-2 px-4 mb-2 py-2  text-white rounded ml-4 border border-gray-400 text-black w-32 cursor-pointer transition-colors duration-300 ease-in-out focus:outline-none`}
    background-color: ${props => props.isActive ? '#167BE7' : 'transparent'};
    color: ${props => props.isActive ? 'white' : 'black'};
    background-color: ${props => props.isActive && darkMode && '#010048'};
    &:hover {
      ${tw`bg-opacity-75 bg-gray-200 text-black`}
    }
    `;
  

  const Star = ({ filled }) => (
    <span style={{color: darkMode && 'black'}}>{filled ? '★' : '☆'}</span>
  );
  
  const Rating = ({ value }) => (
    <div>
      {[...Array(5)].map((_, i) => (
        <Star key={i} filled={i < value} />
      ))}
    </div>
  );

  return (
    <PreviewCards>
      {showFilter && (
      <FilterHolder tw="mb-4">
        <FilterButton style={{color: darkMode && 'white'}} 
          isActive={!filter} onClick={() => setFilter("")}>All</FilterButton>
        <FilterButton style={{color: darkMode && 'white'}} className="nokora" 
          isActive={filter === "business"} onClick={() => setFilter("business")}>អាជីវកម្ម</FilterButton>
        <FilterButton style={{color: darkMode && 'white'}} className="nokora" 
          isActive={filter === "code"} onClick={() => setFilter("code")}>កូដ</FilterButton>
      </FilterHolder>)
      }
      {filteredData.map((item) => (
        <PreviewCardContainer key={item.id}> {/* Changed item to item.id for key */}
          <PreviewCard
            initial="rest"
            whileHover="hover"
            onClick={event => passData(item)}
            target="_blank"
            onMouseEnter={() => setHoveredItem(item.id)}
            onMouseLeave={() => setHoveredItem(null)}
            style={{boxShadow: darkMode && '-0.5rem -0.5rem #E5E4E2', border: darkMode && '1px solid black', backgroundColor: darkMode && '#E5E4E2'}}
          >
            <PreviewCardImageContainer>
              {/* Show video if item is hovered and has an overview, otherwise show the image */}
              {hoveredItem === item.id && item.overview ? (
                <video autoPlay loop tw="w-full h-full">
                  <source src={item.overview} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <CardImage draggable="false" src={item.image}></CardImage>
              )}
            </PreviewCardImageContainer>
            <TextHolder style={{ overflow: "auto" }}>
              <TextHeader className="monoRoman" style={{color: darkMode && 'black'}}>{item.header}</TextHeader>
              {item.courseStatus && <Rating value={item.rating} />}
              {/* {(item.price && !token) && (<PriceText className="nokora" style={{color: darkMode && 'black'}}>${item.price}</PriceText>)} */}
              {!item.courseStatus && <TextHeader tw="text-red-400" >Coming Soon!</TextHeader>}
              <TextContent className="nokora" style={{color: darkMode && 'black'}}>{item.content}</TextContent>
            </TextHolder>
            <PreviewButton className="monoRoman" style={{backgroundColor: darkMode && '#010048', color: darkMode && '#E5E4E2'}}>
              {item.courseStatus ? 'Learn Now' : 'Coming Soon'}
            </PreviewButton>

          </PreviewCard>
        </PreviewCardContainer>
      ))}
    </PreviewCards>
  );
};



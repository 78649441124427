import React from 'react'
import { AiFillCloseCircle } from "react-icons/ai"
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {ImCross} from "react-icons/im";

const modalStlye = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '50px',
    zIndex: 1000,
}

const overlayStyles = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, .2)',
    zIndex: 1000,
}

const BackgroundHolder = tw.div`flex flex-col hover:shadow flex justify-end items-center mb-5 mt-5 bg-blue-100 h-half p-5`
const Title = tw.h1`lg:text-2xl text-xl font-bold text-blue-900`;
const ImageHolder = tw.image``;

export default function Modal({open, schedule, onClose}) {
    if (!open) return null
    return (
    <> 
        <div style={overlayStyles} onClick={onClose}/>
        <div style={modalStlye} >
            <BackgroundHolder>
                <div tw='flex flex-row justify-end items-center w-full pb-3'>
                    <button onClick={onClose}>
                        <ImCross/>
                    </button>
                </div>
                <iframe tw="h-full w-half" src= {schedule} frameborder="0"> </iframe>
            </BackgroundHolder>
        </div>
    </>
  )
}

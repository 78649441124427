import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Header from "Header";
import '../styles/App.css'

import { BsTelegram } from "react-icons/bs";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import heroScreenshotImageSrc from "images/heroImage.png";

import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";





/* Hero */
const Row = tw.div`flex`;
const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pb-16 max-w-screen-2xl mx-auto flex-wrap`;
const Column = tw.div`flex-1`;
const TextColumn = tw(Column)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(HeadingBase)`text-center lg:text-left text-blue-800 leading-snug `;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left  lg:text-base text-gray-900 text-xl lg:text-xl max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw.a`text-lg px-8 py-3 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;

// const ActionButton = tw.button`text-lg px-8 py-3 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)`bg-blue-500 lg:w-1/2`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-blue-700`;
const FeatureText = tw.p`ml-2 text-2xl text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;
const ContentWithPadding = tw.div`py-10 lg:py-12`;
const ButtonHolder = tw.div`flex flex-row items-center sm:justify-center sm:items-center justify-center`;
const FreeSpan = tw.span`text-green-600 font-black`;
export default ({
  features = null,
  primaryButtonText = "រៀនឥឡូវ",
  heading = "រៀនកូដដំបូង",
  description = "🐍 Python គឺជាភាសាដំបូងដែល programmer គ្រប់រូបគួររៀនដំបូង! Python គឺជាភាសាជាប់លេខ 3 ល្បីជាងគេក្នុងពិភពលោក ហើយយើងអាចយកវាទៅបង្កើត Telegram Bot, AI, Excel, Tool, Web App, និងច្រើនទៀត! តោះ FREE តាស 🎉!",
  // description = "Uddom Pang សូមស្វាគមន៍​ មកកាន់ការសិក្សាអំពីកូដជាភាសាខ្មែរ​ សម្រាប់អ្នករៀនដំបូង​ និងរៀនបន្ថែម​ ពួកយេីងផ្ដល់ជូននូវថ្នាក់ថ្មីៗជាបន្តបន្ទាប់​។ លោកអ្នកអាចធ្វេីការបង់ជាប្រចាំខែដេីម្បីទទួលបានចំណេះដឹងផ្នែកកូដកាន់តែច្រេីន"

}) => {




  


  return (
    <AnimationRevealPage enabled>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header/>
          <HeroRow>
            <TextColumn>
              <Heading as="h1" className="nida">{heading} <FreeSpan className="nokora">FREE</FreeSpan></Heading>
              <Description className="nokora">{description}</Description>
            
              <Actions>
                <PrimaryButton className="nokora" href="https://t.me/uddompang_bot" target="_blank">
                {/* <PrimaryButton css={buttonRoundedCss} onClick={navHandler}> */}
                <ButtonHolder>
                  <BsTelegram style={{marginRight: '1rem'}}/>                    
                  {primaryButtonText}

                </ButtonHolder>
                </PrimaryButton>
              </Actions>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image draggable= 'false' src={heroScreenshotImageSrc} />
              </ImageContainer>
            </ImageColumn>
          </HeroRow>
        
        </Content2Xl>
      </Container>
      <MiniCenteredFooter/>
    </AnimationRevealPage>
  );
};



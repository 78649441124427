import { useRef, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const CodeBlock = styled.pre`
  ${tw`bg-blue-900 text-white border border-blue-800 p-4 rounded mt-2 mb-2 relative`}
  overflow: auto;
`;

const CopyButton = styled.button`
  ${tw`absolute top-[7.5px] right-[7.5px] bg-white text-black px-2 py-1 rounded cursor-pointer`}
`;

const Popup = styled.div`
  ${tw`absolute top-0 left-1/2 transform -translate-x-1/2 mt-2 p-2 rounded bg-green-500 text-white text-sm`}
`;

const ResponseContent = ({ response }) => {
  const codeRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleCopyCode = () => {
    if (codeRef.current) {
      const range = document.createRange();
      window.getSelection().removeAllRanges();
      range.selectNode(codeRef.current);
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      
      // Show popup
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 2000); // hide popup after 2 seconds
    }
  };

  // Basic regex to detect code block marked with triple backticks
  const codeRegex = /```([^`]+)```/g;
  const parts = response.split(codeRegex);
  
  return (
    <>
      {parts.map((part, index) => {
        // If index is odd, the part is a code block
        if (index % 2 !== 0) {
          // Remove the first line of the code block
          const modifiedPart = part.split('\n').slice(1).join('\n');
          return (
            <CodeBlock key={index} ref={codeRef}>
              {modifiedPart}
              
              <CopyButton onClick={handleCopyCode}>
                <FontAwesomeIcon className="bg-gray-900" icon={faCopy} style={{marginRight: 5}}/>
                Copy
              </CopyButton>
              
              {showPopup && (
                <Popup>Copied to clipboard!</Popup>
              )}
            </CodeBlock>
          );
        } else {
          // Regular text
          return <span key={index}>{part}</span>;
        }
      })}
    </>
  );
};

export default ResponseContent;

import React, { useContext } from "react";
import tw from "twin.macro";
import '../styles/LoadingScreen.css'
import { DarkModeContext } from "./DarkModeContext";


const LoadingScreen = () => {
  const { darkMode } = useContext(DarkModeContext)

  return (
    <div className="loading-screen"                 style={{
      backgroundColor: darkMode ? "#2c3e50" : "white",
      color: darkMode ? "#f0f0f0" : "#2c3e50",
    }}>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingScreen;

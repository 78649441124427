function ProgressBar({ current, total }) {
    const percentage = (current / total) * 100;

    return (
        <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px', marginRight: '10px' }}>
            <div 
                style={{ 
                    width: `${percentage}%`, 
                    backgroundColor: '#167BE7', 
                    height: '100%', 
                    borderRadius: '5px' 
                }}
            />
        </div>
    );
}

export default ProgressBar;
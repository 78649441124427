import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { useSelector, useDispatch } from "react-redux";
import { LogoLink } from "components/headers/light.js";
import { toggleDarkMode } from "store/authSlice"; // Import the toggleDarkMode action
import { authAction } from "store/authSlice";
import LogoImage from "images/logo.png";
import DropdownNews from "components/DropDownNews";
import DropdownMenu from "components/DropdownMenu";
import DarkModeToggle from "components/DarkModeToggle";
import SalesModal from "components/SalesModal";
import ExclusiveModal from "components/ExclusiveModal";
import { DarkModeContext } from "components/DarkModeContext";


const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between mb-16`;
const NavButton = tw.button`mt-4 lg:mt-0 text-lg transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(NavButton)`lg:w-32  w-full items-center text-gray-100 bg-blue-500 px-6 py-3 border-none rounded hocus:bg-blue-700 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;
const LogoutButton = tw(NavButton)`lg:w-32  w-full items-center text-gray-900 px-6 py-3 border-2 rounded hocus:bg-gray-200 focus:shadow-outline mt-6 md:mt-4 lg:mt-0 `;


const Header = () => {
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [learning, setLearning] = useState([]);
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const { darkMode } = useContext(DarkModeContext);


  const [courseHistory, setCourseHistory] = useState({});
  const [showSalesModal, setShowSalesModal] = useState(false)

  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, []);

  const logout = () => {
    fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/auth/signout', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('darkMode');
        dispatch(authAction.logout());
        navigate('/signin');
      })
      .catch((error) => console.log(error));
  };

  const status = useSelector((state) => state.auth.active);
  const foreverPackage = useSelector((state) => state.auth.foreverPackage);


  const [refresh, updateRefresh] = useState(0);

  const fetchLearning = async () => {
    if (status) {
      try {
        const response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/learning', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user: email }),
        });
        const data = await response.json();
        const courseID = Object.keys(data);

        const fetchedCourses = await Promise.all(
          courseID.map(async (course) => {
            const response = await fetch(`https://usitebackend-36ad67a8d6c6.herokuapp.com/courses/preview/${course}`);
            const jsonResponse = await response.json();
            return jsonResponse;
          })
        );

        setCourseHistory(data);
        setLearning(fetchedCourses.filter(course => !course.error));
      } catch (error) {
        console.error("Failed to fetch learning data:", error);
      }
    }
  }

  useEffect(() => {
    fetchLearning();
  }, [status, token, email, refresh]);

  const deleteHistory = (user, id) => {
    fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/history/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: user, id: id }),
    })
      .then((response) => {
        updateRefresh(refresh + 1);
      })
      .catch((error) => console.error(error));
  };
  
  const [news, setNews] = useState()
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const getNews = async () => {
    let response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/resources/news')
    response = await response.json()


    setNews(response)
  }

  useEffect(() => {
    getNews()
  }, [])

  


  return (
    <NavRow>
      {showSalesModal && <SalesModal onClose={() => setShowSalesModal(false)} />}
      <LogoLink href="/">
        <img src={LogoImage} alt="" />
      </LogoLink>
      <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">

        {/* <DarkModeButton onClick={toggleDarkModeHandler}>
          {darkMode ? "Light Mode" : "Dark Mode"}
        </DarkModeButton> */}

        {!status && 
        <>
          <NavButton className="nokora" style={{ color: 'red', marginLeft: 30}} onClick={() => {navigate('/books')}}>
            សៀភៅកូដ
          </NavButton>
          <NavButton className="nokora" style={{ color: 'green'}} onClick={() => {navigate('/documents')}}>
            កូដ
          </NavButton>
          <NavButton className="nokora" onClick={() => { setShowSalesModal(true) }}>
            ប្រឹក្សា
          </NavButton>
          <NavButton className="nokora" onClick={() => { navigate('/pricing') }}>
            តម្លៃ
          </NavButton>
        </>
        
        }

        {status && 
      < >
        <DarkModeToggle/>
        </>
        }

        {!status ? (
          <>
            
            <NavButton className="nokora" onClick={() => { navigate('/all', {state: {type: ''}}) }}>
              ថ្នាក់
            </NavButton>

            <NavButton className="nokora" onClick={() => { navigate('/roadmap') }}>
            ផែនទីជំនាញ
            </NavButton>
           
            <NavButton className="mono" onClick={() => { navigate('/signin') }}>
              Login
            </NavButton>
            <PrimaryNavLink className="nokora" onClick={() => { window.open("https://t.me/uddomsite", "_blank") }} >
              ចុះឈ្មោះ
            </PrimaryNavLink>
          </>
        ) :

          <>
            <NavButton className="nokora" style={{ color: 'red', marginLeft: 30}} onClick={() => {navigate('/books')}}>
              សៀភៅកូដ
            </NavButton>
            <NavButton className="nokora" style={{ color: 'green'}} onClick={() => {navigate('/documents')}}>
              កូដ
            </NavButton>
            {showModal && <ExclusiveModal onClose={handleCloseModal} />}
            <NavButton className="nokora " style={{ color: darkMode && "#E5E4E2" }} onClick={() => { navigate('/all', {state: {type: ''}}) }}>
              ថ្នាក់
            </NavButton>

            <NavButton className="nokora" style={{ color: darkMode && "#E5E4E2" }} onClick={() => { navigate('/roadmap') }}>
              ផែនទីជំនាញ
            </NavButton>
            <NavButton className="nokora" style={{ color: darkMode && "#E5E4E2" }} onClick={() => { navigate('/support') }}>
              AI (សម្រាប់សួរ)
            </NavButton>
          </>
        }

        {status && (
          <DropdownMenu className="nokora" title="ថ្នាក់កំពុងរៀន" items={learning} deleteHistory={deleteHistory} courseHistory={courseHistory} css={tw`mt-1`} />
        )}

        {status && <LogoutButton className="nokora" style={{ color: darkMode && "#E5E4E2" }} onClick={logout} >
          Logout
        </LogoutButton>}

      </div>
    </NavRow>
  );
};

export default Header;

import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import { DarkModeContext } from "components/DarkModeContext";
import { useSelector } from "react-redux";
import axios from "axios";
import ExclusiveModal from "components/ExclusiveModal";

const StyledContainer = tw(Container)`bg-white -mx-8 -mt-8 pt-8 px-8`;
const ContentContainer = tw.div`flex flex-wrap justify-center`;
const Card = styled.div`
  ${tw`flex flex-col items-center p-5 m-5 border border-gray-300 rounded-lg shadow-lg max-w-lg`}
`;
const Title = tw.h3`text-xl font-semibold mb-4`;
const Description = tw.p`mb-4 text-lg text-gray-700 text-center`;
const Media = styled.div`${tw`mb-4 w-full h-auto`}`;
const DownloadButton = styled.button`${tw`px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out`}`;

export default function DocumentPage() {
  const { darkMode } = useContext(DarkModeContext);
  const [documents, setDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const token = useSelector(state => state.auth.token); // Get the token from the Redux store
  const foreverPackage = useSelector(state => state.auth.foreverPackage)

  useEffect(() => {

    fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/books/codes', {
        headers: {
        'Authorization': `Bearer ${token}`,
        },
    })
        .then((response) => {
        return response.json();
        })
        .then((data) => {
            setDocuments(data)
    
        })
        .catch((error) => console.log(error));
}, [])  




const handleDownload = code => {
  if(token && foreverPackage) {
  axios.get(`https://usitebackend-36ad67a8d6c6.herokuapp.com/books/codes/${code.id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  }).then(response => {
    const downloadUrl = response.data.url;

    // Create a temporary anchor element
    const anchor = document.createElement('a');

    // Set the href attribute to the book's URL
    anchor.href = downloadUrl;

    // Set the download attribute to force the browser to download the file instead of navigating to it
    if (downloadUrl.toLowerCase().endsWith('.pdf')) {
      anchor.setAttribute('download', `${code.title}.pdf`);
    }

    // Append the anchor element to the document body
    document.body.appendChild(anchor);

    // Trigger a click event on the anchor element to start the download
    anchor.click();

    // Remove the anchor element from the document body
    document.body.removeChild(anchor);
  });
} else {
  setShowModal(true);
}

}

  return (
    <AnimationRevealPage>
      <StyledContainer
        style={{
          backgroundColor: darkMode ? "#2c3e50" : "white",
          color: darkMode ? "#f0f0f0" : "#2c3e50",
        }}
      >
        <Content2Xl>
          <Header />
          <ContentContainer>
            {documents.map((doc, index) => (
              <Card key={index}>
                <Title className="mono">{doc.title}</Title>
                <Description className="monoRoman" style={{ color: darkMode && 'white'}}>{doc.description}</Description>
                {doc.type === "video" ? (
                  <Media>
                    <video controls>
                      <source src={doc.url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Media>
                ) : (
                  <Media>
                    <img src={doc.url} alt={doc.title} />
                  </Media>
                )}
                <DownloadButton className="mono" onClick={() => handleDownload(doc)}>
                  Download
                </DownloadButton>
              </Card>
            ))}
          </ContentContainer>
          {showModal && <ExclusiveModal onClose={() => setShowModal(false)} />}

          <Footer/>
        </Content2Xl>
      </StyledContainer>
    </AnimationRevealPage>
  );
};

import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-lg lg:text-xl`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-lg lg:text-xl sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  subheading = "FAQS",
  heading = "មានសំណួរ ?",
  description = "យើងមានចម្លើយទៅលើសំណួរដែលភាគច្រើនត្រូវបានសួរ",
  faqs = [
    {
      question: "តើ website នេះមានមេរៀនអ្វីខ្លះ ?",
      answer:
        "Website នេះគឺមានមេរៀនច្រើនដូចជា Python, Java, Intro To Programming, UI/UX Design, Flutter, Web, App, Hacking, Game, Graphic Design, Networking, Spring, AspDotNet, MERN Stack, VueJS និងច្រើនទៀត"
    },
    {
      question: "តើ Uddom Pang ជាអ្វី ?",
      answer:
        "Udodm Pang គឺកន្លែងអ្នកទាំងអស់គ្នាអាចរៀនកូដបានគ្រប់ជំនាញជាភាសាខ្មែរ"
    },
    
    {
      question: "បង ខ្ញុំអត់ដែលកូដផង អាចរៀនបានដែរឬទេ ?",
      answer:
        "Website នេះគឺសម្រាប់អ្នកចាប់ផ្តើមដំបូងដែលមិនធ្លាប់សរសេរកូដពីមុនសោះ ឬមានបទពិសោធន៍តិចតួច។ វាក៏សម្រាប់អ្នកដែលចេះសរសេរកូដស្រាប់ ប៉ុន្តែចង់រៀនបច្ចេកវិទ្យាថ្មី ឬចង់រៀនបន្ថែម"
    },
    {
      question: "កញ្ចប់មួយជីវិតបង់ម៉េចបង !",
      answer:
        "គឺអាចបង់ម្តង 59.99$ ឬបង់ 3 ដង ម្តង 21.99$ ឬបើបង់ជាមួយមិត្តភក្តិ ម្នាក់ 19.99$ !"
    },
    
    {
      question: "កញ្ចប់មួយជីវិតរៀនបានថ្នាក់អ្វីខ្លះ ហើយម៉េចវិញបង !",
      answer:
        "គឺរៀនបានគ្រប់ថ្នាក់ ទាំងថ្មី និងថ្នាក់ចាស់ រៀនបានរហូត អត់កំណត់ ទោះដាក់ថ្មី ក៏នៅតែរៀន !"
    },
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading className="nokora">{heading}</Heading>
            {description && <Description className="nokora">{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group nokora"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  className="nokora"
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};

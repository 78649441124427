import React from 'react';
import tw from 'twin.macro';

const TestimonialContainer = tw.div`flex items-center mb-6 flex-col bg-gray-200 p-5`;
const ImageContainer = tw.div`flex-row flex w-128 justify-evenly `
const TestimonialImage = tw.div`mb-4`;
const TestimonialImageSrc = tw.img`w-48 h-48 rounded-full object-cover`;
const AUPP = tw.img`w-24 h-24 mt-4 rounded-full object-cover`;

const TestimonialContent = tw.div`flex-1`;
const TestimonialQuote = tw.p`text-xl italic text-black text-center mb-2`;
const TestimonialName = tw.p`text-lg text-black font-bold mb-1 text-center`;
const TestimonialTitle = tw.p`text-base italic text-gray-900 text-center`;

function Testimonial({ image, name, title, quote }) {
  return (
    <TestimonialContainer>
      <ImageContainer>
      <TestimonialImage>
        <TestimonialImageSrc src={image} alt={name} />
      </TestimonialImage>

      </ImageContainer>
      <TestimonialContent>
        <TestimonialQuote className="nokora">“គេហទំព័រ www.uddompang.com គឺជាឧបករណ៍ដែលអាចទុកចិត្តបាន និងមានប្រសិទ្ធភាពសម្រាប់រៀនសរសេរកូដតាមអ៊ីនធឺណិត។ និស្សិតកម្ពុជាជាច្រើនបានសម្តែងការពេញចិត្តចំពោះវគ្គសិក្សាពីគេហទំព័រ។ ខ្ញុំជឿជាក់យ៉ាងមុតមាំថា www.uddompang.com នឹងក្លាយជាធនធានដ៏មានតម្លៃសម្រាប់ស្ថាប័នជាច្រើន”។</TestimonialQuote>
        <TestimonialQuote className="mono">"{quote}"</TestimonialQuote>
        <TestimonialName className="mono">{name}</TestimonialName>
        <TestimonialTitle className="mono">{title}</TestimonialTitle>
      </TestimonialContent>
      <TestimonialImage>
        <AUPP src={'https://upload.wikimedia.org/wikipedia/en/9/98/AUPP_Logo.png'} alt={name} />
      </TestimonialImage>
    </TestimonialContainer>
  );
}

export default Testimonial;

import React, { useContext, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "Header";
import { useState } from "react";
import Courses from "components/learning/MyCourses.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SearchBar from "components/SearchBar";
import { DarkModeContext } from "components/DarkModeContext";

export default function AllCourses() {
  
  const status = useSelector((state) => state.auth.active)
  const email = useSelector((state) => state.auth.email)

  const navigate = useNavigate();
  const passData = (p, email) => {
    if (status){
      if(p.courseStatus == true) {
        navigate('/content', {state: p})
      } 
      else {
        alert('Course will be added soon!')
      }
    } 
    else {
      navigate('/signin')
    }
  }
  const location = useLocation();
  const { type } = location.state ? location.state : '';


  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const { darkMode } = useContext(DarkModeContext)


  return (
    <AnimationRevealPage>
      <Container tw="bg-bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <SearchBar handleSearchChange={handleSearchChange} />
          <Courses type={type ? type: ''} searchTerm={searchTerm} passData={passData} showFilter={true}/>
          <Footer/>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};

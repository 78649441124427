import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion } from "framer-motion";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl, ContentWithVerticalPadding } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Header from "Header";
import './styles/App.css'

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import heroScreenshotImageSrc from "images/heroImage.png";

import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";
import TwoPlansWithDurationSwitcherPricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import DownloadAppCTA from "components/cta/DownloadApp.js";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import MyCourses from "components/learning/MyCourses";
import SingleCol from "components/faqs/SingleCol";
import HomePageModal from "components/portal/HomePageModal";
import LoadingScreen from "components/LoadingScreen";
import Testimonial from "components/Testimonial";
import CustomerFeedbackSection from "components/CustomerFeedbackSection";
import SalesModal from "components/SalesModal";
import PartnershipSection from "components/PartnershipSection";
import SomeCoures from "components/learning/SomeCoures";


const  TitleContainer = tw.button`p-5 sm:p-5 px-20 sm:px-20 transition duration-300 ease-in-out hover:shadow-xl flex justify-around items-center w-full mb-5 xl:mb-2 lg:mb-5 md:mb-5 mt-5 bg-white border border-gray-300`; 
const TitleDiv = tw.div`flex items-center flex-col`
const Title = tw.h1`text-lg sm:text-lg lg:text-2xl md:text-2xl mr-2 text-secondary-700 text-center whitespace-nowrap`
/* Hero */
const Row = tw.div`flex`;
const HeroRow = tw(Row)`flex-col lg:flex-row justify-between items-center pb-16 max-w-screen-2xl mx-auto flex-wrap`;
const Column = tw.div`flex-1`;
const TextColumn = tw(Column)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(HeadingBase)`text-center lg:text-left text-blue-800 leading-snug `;
const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left  lg:text-base text-gray-900 text-xl lg:text-xl max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw.button`text-lg px-8 py-3 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;

// const ActionButton = tw.button`text-lg px-8 py-3 font-bold rounded bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)`bg-blue-500 lg:w-1/2`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-blue-700`;
const FeatureText = tw.p`ml-2 text-2xl text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;
const ContentWithPadding = tw.div`py-10 lg:py-12`;

const SectionContainer = tw(ContentWithPadding)``;
const SectionContainerAchievements = tw.div`bg-gray-200 p-5`;
const SectionHeading = tw(HeadingBase)`text-blue-800`;
const LogoHeader = tw(HeadingBase)`text-black`;
const SectionDescription = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl text-2xl mb-4`;
const LogoHolder = tw.div`flex justify-evenly`
const LogoImages = tw.img`lg:w-1/6 md:w-1/4 sm:w-1/4`
const PromotionHolder = tw.div`relative h-64 md:h-auto md:w-auto items-center justify-center flex p-5`
const Promotion = tw.img`object-cover w-full h-full lg:w-1/2 lg:h-1/2 hover:-translate-y-3 duration-300`
const PromotionClick = tw.a`justify-center flex`
export default ({
  features = null,
  primaryButtonText = "ប្រឹក្សា FREE",
  buttonRoundedCss = "",
  heading = "រៀន ផ្តើមអាជីព និងរកចំណូល",
  description = "រៀនបច្ចេកវិទ្យាថ្មីៗដូចជា code, networking, ethical hacking, UX design, digital marketing, graphic design ជាភាសាខ្មែរ! ចុះឈ្មោះឥឡូវតាមតេលេក្រាម!",
  // description = "Uddom Pang សូមស្វាគមន៍​ មកកាន់ការសិក្សាអំពីកូដជាភាសាខ្មែរ​ សម្រាប់អ្នករៀនដំបូង​ និងរៀនបន្ថែម​ ពួកយេីងផ្ដល់ជូននូវថ្នាក់ថ្មីៗជាបន្តបន្ទាប់​។ លោកអ្នកអាចធ្វេីការបង់ជាប្រចាំខែដេីម្បីទទួលបានចំណេះដឹងផ្នែកកូដកាន់តែច្រេីន"

}) => {

  const [data, setData] = useState([])
  const [title, setTitle] = useState([])
  const [copywrite, setCopywrite] = useState([])
  const [logos, setLogos] = useState([])
  const [promotionPic, setPromotionPic] = useState([])
  const [userAmount, setUserAmount] = useState([])
  const [showSalesModal, setShowSalesModal] = useState(false);

  const getData = async () => {
    let response = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/resources/landingpage')
    response = await response.json()

    setData(response.dataList)

    setTitle(response.updatesList[0].title)
    setCopywrite(response.updatesList[0].copywrite)
    setPromotionPic(response.updatesList[0].sale)
    setUserAmount(response.updatesList[0].userAmount)

    setLogos(response.logoList)
  }


  const status = useSelector((state) => state.auth.active)


  const navigate = useNavigate()
  const navHandler = () => {
    navigate('/content')
  }
  const passData = p => {
    if(p.courseStatus == true) {
      navigate('/content', {state: p})
    } else {
      alert('Course will be added soon!')
    }
  }

  const showCopyWrite = () => {
    setShowModal(!showModal);
  }
  
  const showPopup = () => {
    setPopup(!popup);
  }
  
  const [isLoading, setIsLoading] = useState(true)

  const [popup, setPopup] = useState(true)

  useEffect(()=> {
    getData().then(() => {
      setIsLoading(false) 
    })
  }, [])

  const noOfCourses = data.length;

  features = features || [
    `${noOfCourses} មុខវិជ្ជា /ថ្នាក់`,
    "និយាយខ្មែរទាំងអស់",
    "ស្តង់ដា",
    "មានថ្នាក់ទៅតាមកម្រិត",
    "បច្ចេកវិទ្យាថ្មីៗ",
    "មានបង្រៀនពីសូន្យ "
  ];
  
  const [showModal, setShowModal] = useState(false);

  if(isLoading) {
    return <LoadingScreen/>
  }

    

  return (
    <AnimationRevealPage enabled>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header/>
          {showSalesModal && <SalesModal onClose={() => setShowSalesModal(false)} />}
          <TitleContainer className="nokora" onClick={showCopyWrite}>
            <TitleDiv>
              <Title>{title}</Title>
            </TitleDiv>
          </TitleContainer>
          <HomePageModal showModal={showModal} toggleModal={showCopyWrite} copywrite={copywrite}/>
          {/* <SalesPopup showModal={popup} toggleModal={showPopup} copywrite={copywrite}/> */}
          <HeroRow>
            <TextColumn>
              <Heading as="h1" className="nida">{heading}</Heading>
              <Description className="nokora">{description}</Description>
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText className="nokoraBold">{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
              <Actions>
                <PrimaryButton className="nokora" onClick={() => setShowSalesModal(true)}>
                {/* <PrimaryButton css={buttonRoundedCss} onClick={navHandler}> */}
                  {primaryButtonText}
                </PrimaryButton>
              </Actions>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image draggable= 'false' src={heroScreenshotImageSrc} />
              </ImageContainer>
            </ImageColumn>
          </HeroRow>
          <SectionContainer>
            <SectionHeading className="nokora">សេចក្តីគាំទ្រ</SectionHeading>
            <Testimonial 
            image={'https://firebasestorage.googleapis.com/v0/b/uddompang-dfd58.appspot.com/o/drMing.jpg?alt=media&token=8c4f59a0-0fc5-4091-a83a-532270764a30'} 
            name={"Dr Ng Tek Ming"}
            title={"Associate Professor and Dean, Faculty of Information Technology, American University of Phnom Penh"}
            quote={"The website www.uddompang.com is a reliable and effective tool for learning coding online. Many Cambodian students have expressed satisfaction with the courses from the website. I strongly believe www.uddompang.com will be a valuable resource for many institutions."}
            />
          </SectionContainer>
            <CustomerFeedbackSection/>
          <SectionContainer>
            
          <SectionContainer>
            <PartnershipSection/>
          </SectionContainer>
          <SectionHeading className="nokora">សមិទ្ធិផល</SectionHeading>
          <SectionContainerAchievements>
            <LogoHeader className="nokora">មានសិស្ស</LogoHeader>
            <SectionDescription className="nokora">
              ចំនួន {userAmount}
            </SectionDescription>
            <LogoHeader className="nokora">ប្រើដោយ និងគាំទ្រដោយ</LogoHeader>
            <LogoHolder>
              {logos.map((item)=> (
                <LogoImages src={item.link}/>
              ))}
            </LogoHolder>
          </SectionContainerAchievements>
          </SectionContainer>
          <SectionContainer id="landingPageDemos">
            <SectionHeading className="nokora">ថ្នាក់រៀន</SectionHeading>
            <SectionDescription className="nokora">
              ខាងប្អូនមាន {noOfCourses} មេរៀនទាក់ទងនឹងកូដ. ថ្នាក់កូដដែលតម្រូវការ ថ្មី និងជួយទៅដល់អាជីពអ្នកនឹងមកបន្តរបន្ទាប់ទៀត! 
            </SectionDescription>
            <SomeCoures passData={status ? passData : navHandler} showFilter={false} />
          </SectionContainer>

          {!status && <TwoPlansWithDurationSwitcherPricing/>}

        </Content2Xl>
      </Container>
      <SingleCol/>
      <MiniCenteredFooter/>
    </AnimationRevealPage>
  );
};



import React, { useRef, useState, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { FaPlay, FaPause, FaVolumeMute, FaVolumeUp, FaExpand, FaCompress, FaForward, FaBackward } from 'react-icons/fa';

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: ${props => (props.isFullScreen ? '100vh' : 'auto')};
`;

const Video = styled.video`
  width: 100%;
  height: ${props => (props.isFullScreen ? '100vh' : 'auto')};
`;

const BottomBar = styled.div`
  ${tw`absolute left-0 right-0 bottom-0 flex items-center justify-between p-2 bg-black bg-opacity-50 transition-opacity duration-500`}
  opacity: 0;
`;

const ControlButton = styled.button`
  ${tw`text-white rounded p-2 m-1 focus:outline-none hover:bg-gray-700`}
`;

const TimeIndicator = tw.div`text-white text-xs`;

const ProgressBarContainer = tw.div`flex-grow mx-2`;
const ProgressBar = tw.div`h-2 bg-gray-800 rounded cursor-pointer`;
const ProgressBarFill = styled.div`
  ${tw`h-2 bg-blue-500 rounded`}
  width: ${(props) => props.progress}%;
`;

const CustomVideoPlayer = ({ src }) => {
  const [timeoutId, setTimeoutId] = useState(null);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedMetadata = () => {
      setDuration(videoElement.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(videoElement.currentTime);
    };

    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };

    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleProgressClick = (event) => {
    const newTime = (event.clientX / event.target.offsetWidth) * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteUnmute = () => {
    const videoElement = videoRef.current;
    videoElement.muted = !videoElement.muted;
    setIsMuted(videoElement.muted);
  };

  const handleVolumeChange = (event) => {
    const videoElement = videoRef.current;
    const newVolume = parseFloat(event.target.value);
    videoElement.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  const handleTimeSeek = (event) => {
    const videoElement = videoRef.current;
    const seekTime = parseFloat(event.target.value);
    videoElement.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleForward = () => {
    const videoElement = videoRef.current;
    videoElement.currentTime += 10;
    setCurrentTime(videoElement.currentTime);
  };

  const handleBackward = () => {
    const videoElement = videoRef.current;
    videoElement.currentTime -= 10;
    setCurrentTime(videoElement.currentTime);
  };

  const toggleFullScreen = () => {
    const videoContainer = videoRef.current.parentNode;

    if (!isFullScreen) {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.mozRequestFullScreen) {
        videoContainer.mozRequestFullScreen();
      } else if (videoContainer.webkitRequestFullscreen) {
        videoContainer.webkitRequestFullscreen();
      } else if (videoContainer.msRequestFullscreen) {
        videoContainer.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    setIsFullScreen(!isFullScreen);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    if (showControls) {
      BottomBar.defaultProps = {
        style: { opacity: 1 },
      };
    } else {
      BottomBar.defaultProps = {
        style: { opacity: 0 },
      };
    }
  }, [showControls]);

  const handleMouseEnter = () => {
    if (!isFullScreen) {
      setShowControls(false);
    }
  };

  const handleMouseLeave = () => {
    if (!isFullScreen) {
      setShowControls(true);
    }
  };

  const handleMouseMove = () => {
    if (isFullScreen) {
      setShowControls(true);
  
      // Hide controls after 3 seconds
      const id = setTimeout(() => {
        setShowControls(false);
      }, 3000);
  
      setTimeoutId(id);
    }
  };
  

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (showControls && timeoutId === null) {
      // Show controls immediately if the timeout is not active
      setShowControls(true);
    }
  }, [showControls, timeoutId, isFullScreen]);
  

  return (
<VideoContainer
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
  onMouseMove={handleMouseMove}
  isFullScreen={isFullScreen} // Pass isFullScreen as a prop
>
  <Video
    ref={videoRef}
    src={src}
    isFullScreen={isFullScreen} // Pass isFullScreen as a prop
  />

      <BottomBar>
        <ControlButton onClick={handlePlayPause}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </ControlButton>

        <ControlButton onClick={handleBackward}>
          <FaBackward />
        </ControlButton>

        <ControlButton onClick={handleForward}>
          <FaForward />
        </ControlButton>

        <ProgressBarContainer onClick={handleProgressClick}>
          <ProgressBar>
            <ProgressBarFill progress={(currentTime / duration) * 100} />
          </ProgressBar>
        </ProgressBarContainer>

        <TimeIndicator>{formatTime(currentTime)}</TimeIndicator>

        <ControlButton onClick={handleMuteUnmute}>
          {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
        </ControlButton>

        <input
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={isMuted ? 0 : volume}
          onChange={handleVolumeChange}
        />

        <ControlButton onClick={toggleFullScreen}>
          {isFullScreen ? <FaCompress /> : <FaExpand />}
        </ControlButton>
      </BottomBar>
    </VideoContainer>
  );
};

export default CustomVideoPlayer;

import Header from 'Header';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import tw from 'twin.macro';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import { RingLoader, DotLoader, BeatLoader } from "react-spinners";
import ResponseContent from 'components/ResponseContent';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../styles/ConfirmAlertStyles.css'
const Title = styled.h1`
  ${tw`text-center lg:text-left md:text-left xl:text-left text-2xl mb-4`}
`;

const Form = styled.form`
  ${tw`flex flex-col`}
`;

const Label = styled.label`
  ${tw`mb-2`}
`;

const TextArea = styled.textarea`
  ${tw`p-2 mb-2 border border-gray-300 rounded`}
`;

const Button = styled.button`
  ${tw`p-2 bg-blue-500 text-white border-none cursor-pointer w-5/6 rounded-l-lg`}
`;

const DeleteButton = styled.button`
  ${tw`p-2 bg-red-500 text-white border-none cursor-pointer w-1/6 rounded-r-lg`}
`;

const ButtonContainer = styled.div`
  ${tw`p-2 flex `}
`

const ToggleLang = tw.button`flex items-center justify-center w-full h-12 mb-2 mt-2 bg-gray-100 hover:bg-gray-300 transition duration-300 ease-in-out hover:shadow border  border-gray-200`;

const TranslatedTextContainer = styled.div`
  ${tw`mt-8 border border-gray-300 p-4 rounded opacity-0 transition-opacity duration-500`}
  &.show {
    ${tw`opacity-100`}
  }
`;

const TranslatedTextTitle = styled.h2`
  ${tw`text-xl font-semibold mb-2`}
`;

const TranslatedTextContent = styled.pre`
  ${tw`text-gray-700 font-mono whitespace-pre-wrap`}
  background-color: #f8f8f8;
  padding: 8px;
  overflow-x: auto;
`;

const LoadingIndicator = styled.div`
  ${tw`text-center mt-4 flex items-center justify-center`}
`;

const LoadingBuffer = styled.div`
  ${tw`h-4`}
`;
const HighlighSpan = styled.span`
  ${tw`text-red-500`}
`
const ErrorContainer = styled.div`
  ${tw`mt-4 bg-red-500 text-white p-2 rounded text-center`}
`;
const UserChatContainer = styled.div`
  ${tw`p-4 mt-4 border border-black border font-bold rounded-t-lg bg-white`}
`;

const AIChatContainer = styled.div`
  ${tw`p-4 mb-4 bg-gray-100 border border-t-0 border-black rounded-b-lg`}
`;

const ChatContent = styled.p`
  ${tw`text-base`}
`;

const BufferContainer = tw.div`h-6`

const HistoryContainer = tw.div`mt-12`

const Support = () => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTranslatedText, setShowTranslatedText] = useState(false);
  const [error, setError] = useState('');
  const [lang, setLang] = useState('khmer'); // Initial language is set to Khmer
  const [chatHistory, setChatHistory] = useState([]);
  

  const email = useSelector(state => state.auth.email);

  const fetchChatHistory = () => {
    axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/support/history', {email: email}).then((res) => {
      setChatHistory(res.data.response || []);
    });
  };
  

  useEffect(() => {
    fetchChatHistory();
  }, []);


  const handleLanguageToggle = () => {
    setLang(prevLang => (prevLang === 'khmer' ? 'english' : 'khmer'));
  };

  const handleDelete = () => {
    confirmAlert({
      message: 'ចឹងបងចង់លុបប្រវត្តិឆាតទាំងអស់?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios.post('https://usitebackend-36ad67a8d6c6.herokuapp.com/support/delete', {email: email}).then((res) => {
              setChatHistory(res.data.response);
            });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  useEffect(() => {
    if (response) {
      setShowTranslatedText(true);
    } else {
      setShowTranslatedText(false);
    }
  }, [response, lang]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (prompt === '') {
      setError('Please enter something!');
      return;
    }

    setLoading(true);
    setError('');

    // Simulate a loading delay
    await new Promise(resolve => setTimeout(resolve, 1000));

    try {
      const res = await fetch('https://usitebackend-36ad67a8d6c6.herokuapp.com/support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, prompt, lang }),
      });

      if (res.ok) {
        const data = await res.json();
        fetchChatHistory();  // Refetch the history after getting a response

        setResponse(data.response);
      } else {
        setError('An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimationRevealPage>
      <Container tw="bg-white -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <Header />
          <Container>
            <Form onSubmit={handleSubmit}>
              <Title className='nokora'>សំណួរ: <HighlighSpan>បើអត់ដើរ សុំចុចសញ្ញាធុងសម្រាម ហើយលុប រួចសាកម្តងទៀត</HighlighSpan></Title>
              <TextArea
                rows={4}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className='nokora'
                placeholder='សូមបញ្ចូលសំណួររបស់អ្នកនៅទីនេះ'
              />
              <ButtonContainer>
                <Button type="submit" className='monoRoman'>សួរ</Button>
                <DeleteButton type="button" onClick={handleDelete} className='monoRoman'>
                  <FontAwesomeIcon icon={faTrash}/>
                </DeleteButton>

              </ButtonContainer>
            </Form>
            {loading ? (
              <BufferContainer>
                <LoadingBuffer/>
                <LoadingIndicator>
                  <BeatLoader color="#243E63" size={12} />
                </LoadingIndicator>
              </BufferContainer>
            ) : 
            <BufferContainer>
            </BufferContainer>
            }
            {error && (
              <ErrorContainer>{error}</ErrorContainer>
            )}

            <HistoryContainer>
              {chatHistory ? chatHistory.map((chat, index) => (
                chat.role === 'user' ? (
                  <UserChatContainer key={index}>
                    <ChatContent>{chat.content}</ChatContent>
                  </UserChatContainer>
                ) : (
                  <AIChatContainer key={index}>
                    <ResponseContent response={chat.content}/>
                  </AIChatContainer>
                )
              )) : null}
            </HistoryContainer>



          </Container>
          <Footer/>
        </Content2Xl>
      </Container>
      {loading && <LoadingBuffer />}
    </AnimationRevealPage>
  );
};

export default Support;

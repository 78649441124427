import React, { useState, useRef, useEffect, useContext } from 'react';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from './DarkModeContext';

const DropdownWrapper = tw.div`relative inline-block text-left`;
const DropdownButton = tw.button`mt-4 lg:mt-0 text-lg transition duration-300 font-medium pb-1 border-b-2 mr-12 lg:mx-12 md:mx-12 text-red-700 border-red-400 hocus:border-red-700`;
const DropdownContent = tw.div`overflow-scroll h-64 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10`;
const DropDownItemContainer = tw.button`px-2 py-2`
const DropDownContainer = tw.div`flex items-center space-x-2 hover:bg-gray-100 hover:text-gray-900 rounded flex flex-row w-48`
const DropDownImage = tw.img `w-16 h-16 object-cover rounded `
const DropDownText = tw.p`text-sm font-medium text-gray-700 text-left`
const DropdownMenu = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const navigate = useNavigate()

  const passData = (p) => {
    navigate('/content', {state: p})
  }

  const {darkMode} = useContext(DarkModeContext)

  return (
    <DropdownWrapper ref={wrapperRef}>
      <DropdownButton className="nokora" onClick={toggleDropdown}>
        {title}
        <span tw="ml-2">
          {isOpen ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
        </span>
      </DropdownButton>
      {isOpen && (
        <DropdownContent tw="py-1 h-2">
          {items.map((item) => (
            <DropDownItemContainer key={item.id} style={{backgroundColor: darkMode && '#E5E4E2'}}>
              <DropDownContainer>
                <DropDownImage src={item.image} alt={item.header}/>
                <DropDownText>{item.news}</DropDownText>
              </DropDownContainer>
            </DropDownItemContainer>
          ))}
        </DropdownContent>
      )}
    </DropdownWrapper>
  );
};

export default DropdownMenu;

import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { useNavigate } from "react-router-dom";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-900`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`flex flex-wrap sm:border-2 border-2 rounded-full p-4 mt-8 justify-around`;
const SwitchButton = styled.button`
  ${tw`whitespace-nowrap h-3/12 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300`}
  ${props => props.active && tw`bg-blue-500 text-gray-100`}
`;

const PlansContainer = tw.div`flex flex-col md:flex-row items-center md:items-center relative justify-center`;
const Plan = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 mt-8 md:mr-8 lg:mr-12 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 p-2 -mx-8 bg-gray-100 rounded-t-lg `}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-900`}
  }
`;

const PlanAction = tw.div`px-4 pb-8 content-center`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform bg-blue-500 hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const VideoSection = tw.div`w-full flex justify-center`;
const VideoTitle = tw.h2`text-4xl font-bold text-gray-900 mb-4 text-center `;
const VideoContainer = tw.div`w-full md:w-2/3 lg:w-1/2 xl:w-2/5`;
const VideoIframe = styled.iframe`
  ${tw`w-full`}
  height: 300px; // Adjust height as needed
`;

export default ({
  subheading = "ការបង់ប្រាក់",
  heading = "កញ្ចប់បង់ប្រាក់.",
  description = "អាចបង់ជាខែក៏បាន បង់ជាឆ្នាំក៏បាន",
  plans = null,
  primaryButtonText = "Buy Now",
  planDurations = [
    {
      text: "1 ខែ",
      switcherText: "Monthly",
    },
    {
      text: "3 ខែ",
      switcherText: "3 Months",
    },
    {
      text: "1 ឆ្នាំ",
      switcherText: "Yearly",
    },
    {
      text: "ជីវិត",
      switcherText: "Forever",
    },
    {
      text: "ជីវិត / 3 ដង",
      switcherText: "Pay Later",
    }
  ]
}) => {
  const defaultPlans = [
    {
      name: "Pro Plan",
      durationPrices: ["$9.99", "$24.99", "$49.99", "$59.99", "21.99$"],
      features: ["រៀនបានគ្រប់", "ប្រើផែនទីជំនាញបាន", "រៀនបានគ្រប់​ eBooks", "ប្រើបានគ្រប់ code template", "ចំណួយតាមតេលេក្រាម"]
    },
  ];

  if (!plans) plans = defaultPlans;

  const [activeDurationIndex, setActiveDurationIndex] = useState(0);

  const navigate = useNavigate();

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer className="nokora">
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading className="nokora">{heading}</Heading>
          {description && <Description>{description}</Description>}
          <PlanDurationSwitcher >
            {planDurations.map((planDuration, index) => (
              <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => setActiveDurationIndex(index)} className="monoRoman">{planDuration.switcherText}</SwitchButton>
            ))}
          </PlanDurationSwitcher>
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="priceAndDuration ">
                  <span className="price mono">{plan.durationPrices[activeDurationIndex]}</span>
                  <span className="slash mono"> / </span>
                  <span className="duration nokora ">
                    {plan.durationPrices[activeDurationIndex] === "$59.99" ? "ជីវិត" : planDurations[activeDurationIndex].text}
                    </span>
                </span>
                <span className="name nokora">{plan.name}</span>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature nokora" >
                    {feature}
                  </span>
                ))}
              </PlanFeatures>
              <PlanAction>
                <BuyNowButton onClick={() => navigate('/courses')} className="monoRoman">{primaryButtonText}</BuyNowButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
